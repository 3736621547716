import {UI_CLEANUP_TAB_LIST, UI_UPDATE_ACTIVE_TAB, UI_UPDATE_TAB_LIST} from "../actions/action.types";

const initialState = {
    activeTab: 0,
    tabNamesList: [],
    headerData: null
}
export const UiReducer = (state = initialState, action) => {
    switch (action.type) {
        case UI_UPDATE_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.payload
            }
        }
        case UI_UPDATE_TAB_LIST: {
            return {
                ...state,
                tabNamesList: action.payload
            }
        }
        case UI_CLEANUP_TAB_LIST: {
            return initialState
        }
        default:
            return state
    }
}