import React, {useEffect, useState} from 'react'
import {DashboardCardComponent} from "./DashboardCard";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {getConfig} from "../../configs/config_loader";
import {useDispatch} from "react-redux";
import {UiActions} from "../../store/actions/UiActions";
import {Spinner} from "../shared/Spinner";

const dashboard_items = [
    {
        title: "Accounts",
        image: "accounts.svg",
        route: "/accounts",
        text: "Manage employee accounts",
        module: 'accounts'
    },
    {
        title: "Events",
        image: "events.svg",
        route: "/events",
        text: "Schedule important events",
        module: 'events'
    },
    {
        title: "Medications",
        image: "medications.svg",
        route: "/medications",
        text: "Manage resident medications",
        module: 'medications'
    },
    {
        title: "Residents",
        image: "residents.svg",
        route: "/residents",
        text: "Manage your residents",
        module: 'residents'
    },
    {
        title: "Tasks",
        image: "tasks.svg",
        route: "/tasks",
        text: "See what needs to be done next",
        module: 'tasks'
    },
    {
        title: "Statistics",
        image: "stats.svg",
        route: "/stats",
        text: "See the data visualized",
        module: 'statistics'
    }
]

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none",
    },
    cta: {
        padding: theme.spacing(1, 6),
        marginTop: theme.spacing(2)
    },
    preLoginPage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    wText: {
        fontWeight: 'bold'
    }
}))

const Dashboard = () => {
    const classes = useStyles();
    const [config, set_config] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        getConfig().then(set_config)
        dispatch(UiActions.updateTabList(null))
    }, [])

    if (!config)
        return <Spinner/>

    return (
        <Grid container justify="center" spacing={2}>
            {
                dashboard_items.filter(card => config.modules.indexOf(card.module) !== -1).map(it =>
                    <Grid item xs={12} md={6} lg={5} key={it.title}>
                        <Link to={it.route} className={classes.link}>
                            <DashboardCardComponent
                                title={it.title}
                                image={it.image}
                                text={it.text}
                            />
                        </Link>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default Dashboard
