import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {EmployeeActions} from '../../store/actions/EmployeeActions'
import {AuthActions} from "../../store/actions/AuthActions";
import {useFormUtils} from "../shared/forms/FormUtils";
import {AuthFormFields} from "../shared/fields/AuthFields";
import {EmployeeFormFields} from "../shared/fields/EmployeeFields";
import {AddressFields} from "../shared/fields/AddressFields";
import {ResponseStatus} from "../shared/Constants";


export const useEmployeeDetailsService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const {fieldsToRequestObject} = useFormUtils()
    const {id} = useParams()
    const dispatch = useDispatch()
    const {employee, isEmployeeLoading} = useSelector(state => state.employees)

    const headerTitle = id ? 'Employee details' : 'Create employee'
    const headerSubtitle = id ? 'View and edit employee details' : 'Enter employee data'
    const formButtonText = id ? 'Save employee' : 'Create employee'
    const fields = [...AuthFormFields, ...AddressFields, ...EmployeeFormFields]
    const {
        accountSaveStatus,
        accountSaveMessage,
        accountUpdateStatus,
        accountUpdateMessage
    } = useSelector(state => state.auth)
    const history = useHistory()

    const onFormSubmit = (formData) => {
        getAccessTokenSilently().then(token => {
            if (id) {
                const request = stateToRequestSave(formData)
                dispatch(AuthActions.saveAccount(token, id, "employee", request))
            } else {
                const request = stateToRequest(formData)
                dispatch(AuthActions.registerUser(token, request))
            }
        })
    }

    const pushHistory = () => {
        if (accountSaveStatus === ResponseStatus.SUCCESS || accountUpdateStatus === ResponseStatus.SUCCESS) {
            history.push("/employees")
        } else if (accountSaveStatus === ResponseStatus.ERROR) {
            alert(accountSaveMessage)
        } else if (accountUpdateStatus === ResponseStatus.ERROR) {
            alert(accountUpdateMessage)
        }
        return () => {
            dispatch(AuthActions.cleanup())
        }
    }

    useEffect(pushHistory, [accountSaveStatus, accountSaveMessage, accountUpdateStatus, accountUpdateMessage])

    const stateToRequestSave = (formData) => {
        const authRequestObj = fieldsToRequestObject(AuthFormFields, formData)
        const addressRequestObj = fieldsToRequestObject(AddressFields, formData)
        const employeeRequestObj = fieldsToRequestObject(EmployeeFormFields, formData)
        return {
            auth_user: {
                ...authRequestObj,
                groups: []
            },
            positions: employeeRequestObj['positions'],
            type: 'employee',
            ...addressRequestObj,
            ...employeeRequestObj
        }
    }

    const stateToRequest = (formData) => {
        const authRequestObj = fieldsToRequestObject(AuthFormFields, formData)
        const addressRequestObj = fieldsToRequestObject(AddressFields, formData)
        const employeeRequestObj = fieldsToRequestObject(EmployeeFormFields, formData)
        return {
            ...authRequestObj,
            positions: employeeRequestObj['positions'],
            type: 'employee',
            employee: {
                ...addressRequestObj,
                ...employeeRequestObj
            }
        }
    }

    const fetchEmployee = useCallback(() => {
        if (id) {
            getAccessTokenSilently().then(token => {
                dispatch(EmployeeActions.fetchEmployee(token, id))
            })
        }

        return () => {
            dispatch(EmployeeActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently, id])

    useEffect(fetchEmployee, [id])

    return {
        id,
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        employee,
        fields,
        isEmployeeLoading
    }
}