import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../store/actions"
import {Button, Grid, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useParams} from "react-router";
import {Spinner} from "../shared/Spinner";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        cursor: 'pointer'
    },
    link_fields: {
        display: "flex",
        marginTop: theme.spacing(1),
        width: '30%',
        justifyContent: "space-between"
    }
}))

const DiagnosisForm = ({
                           diagnosis,
                           doctors,
                           onDiagnosisFetch,
                           onDiagnosisCreate,
                           onDiagnosisUpdate,
                           onClearDiagnosis,
                           onDiagnosisSave,
                           onDoctorsFetch
                       }) => {
    const {diagnosis_id} = useParams()
    const history = useHistory()
    const {getAccessTokenSilently} = useAuth0()
    const [can_edit, set_editable] = useState(!diagnosis_id || false)
    const classes = useStyle()

    useEffect(() => {
        diagnosis_id
            ? getAccessTokenSilently().then(token => {
                onDiagnosisFetch(token, diagnosis_id)
            })
            : onClearDiagnosis()
        getAccessTokenSilently().then(token => {
            onDoctorsFetch(token)
        })
    }, [])

    let disabled = false

    const handleChange = (event) => {
        if (!can_edit) return;
        const {name, value} = event.target
        onDiagnosisUpdate(name, value)
    }

    const handleCreate = () => {
        getAccessTokenSilently().then(token => {
            onDiagnosisCreate(token, diagnosis)
        })
    }

    const handleSave = () => {
        getAccessTokenSilently().then(token => {
            onDiagnosisSave(token, diagnosis_id, diagnosis)
        })
    }

    const handleDoctorClick = () => {
        if (diagnosis.doctor)
            history.push(`/doctors/details/${diagnosis.doctor}`)
    }

    if (!diagnosis || !doctors)
        return <Spinner/>

    return (
        <React.Fragment>
            <div className={classes.header}>
                <div>
                    <Typography
                        variant={"h3"}>{diagnosis_id ? "Diagnosis Details" : "Diagnosis report"}</Typography>
                    <Typography
                        variant={"body1"}>{diagnosis_id ? "View and update diagnosis" : "Create new diagnosis"}</Typography>
                </div>
                {
                    diagnosis_id && <FormControlLabel
                        control={
                            <Switch checked={can_edit}
                                    onChange={e => set_editable(e.target.checked)}
                                    name="disabled"
                                    color={'primary'}/>
                        }
                        label="Edit diagnosis"
                    />
                }
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth placeholder={'Doctor'}>
                        <InputLabel id="doctor-label">Doctor</InputLabel>
                        <Select
                            type={'text'}
                            labelId={'doctor-label'}
                            onChange={handleChange}
                            disabled={disabled}
                            name={'doctor'}
                            value={diagnosis.doctor}
                            fullWidth
                        >
                            {doctors.map(doc => <MenuItem key={doc.id}
                                                          value={doc.id}>{doc.first_name} {doc.last_name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <div className={classes.link_fields}>
                        <Typography variant={'subtitle1'}
                                    color={'primary'}
                                    onClick={handleDoctorClick}
                                    className={classes.link}
                        >
                            View details
                        </Typography>
                        <Typography variant={'subtitle1'}
                                    color={'primary'}
                                    onClick={() => history.push('/doctors/create')}
                                    className={classes.link}
                        >
                            Create new
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'history'}
                        value={diagnosis.history}
                        label={'Diagnosis History'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'self_diagnosis'}
                        value={diagnosis.self_diagnosis}
                        label={'Self Diagnosis'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'provisional_diagnosis'}
                        value={diagnosis.provisional_diagnosis}
                        label={'Provisional Diagnosis'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'differential_diagnoses'}
                        value={diagnosis.differential_diagnoses}
                        label={'Differential Diagnosis'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'symptoms'}
                        value={diagnosis.symptoms}
                        label={'Symptoms'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'judgment'}
                        value={diagnosis.judgment}
                        label={'Judgment'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant={'standard'}
                        type={'text'}
                        name={'plan'}
                        value={diagnosis.plan}
                        label={'Plan'}
                        onChange={handleChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        !diagnosis_id && <Button color={'secondary'}
                                                 size={'large'}
                                                 variant={'contained'}
                                                 onClick={handleCreate}
                        >
                            Create Diagnosis
                        </Button>
                    }
                    {
                        can_edit && diagnosis_id && <Button color={'secondary'}
                                                            size={'large'}
                                                            variant={'contained'}
                                                            onClick={handleSave}
                        >
                            Save Diagnosis
                        </Button>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

const mapStateToProps = store => {
    return {
        diagnosis: store.medications.diagnosis_details,
        doctors: store.medications.doctors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDiagnosisFetch: (token, id) => dispatch(actions.fetchDiagnosis(token, id)),
        onDiagnosisCreate: (token, data) => dispatch(actions.createDiagnosis(token, data)),
        onDiagnosisUpdate: (name, value) => dispatch(actions.updateDiagnosisDetails(name, value)),
        onDiagnosisSave: (token, id, data) => dispatch(actions.saveDiagnosis(token, id, data)),
        onClearDiagnosis: () => dispatch(actions.clearDiagnosisDetails()),
        onDoctorsFetch: (token) => dispatch(actions.fetchDoctors(token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisForm)
