import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {ResponseStatus} from "../shared/Constants";
import {GuardianActions} from "../../store/actions/GuardianActions";

export const useGuardianListService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()
    const [denseView, setDenseView] = useState(true)

    const {
        guardians,
        areGuardiansLoading,
        guardiansFetchStatus,
        guardiansFetchMessage
    } = useSelector(state => state.guardians)

    const navigateToGuardianDetails = (employeeId) => {
        history.push(`/guardians/${employeeId}`)
    }

    const navigateToGuardianCreate = () => {
        history.push('/guardians/create')
    }

    const denseViewHandler = () => {
        setDenseView(!denseView)
    }

    const updateTableData = useCallback(() => {
        getAccessTokenSilently().then(token => {
            dispatch(GuardianActions.fetchGuardians(token))
        })

        return () => {
            dispatch(GuardianActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently])

    const handleError = useCallback(() => {
        if (guardiansFetchStatus === ResponseStatus.ERROR) {
            alert(guardiansFetchMessage)
        }
    }, [guardiansFetchMessage, guardiansFetchStatus])

    useEffect(updateTableData, [])
    useEffect(handleError, [guardiansFetchStatus])

    return {
        guardians,
        areGuardiansLoading,
        navigateToGuardianDetails,
        navigateToGuardianCreate,
        denseViewHandler,
        denseView
    }
}