import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import shadows from "@material-ui/core/styles/shadows";

const styles = makeStyles((theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 4),
        // minHeight: '200px',
        height: "80%",
        backgroundColor: "transparent",
        color: "#13336a",
        border: "1px solid #13336a",
        borderRadius: "15px",
        '&:hover': {
            boxShadow: shadows[12],
            cursor: "pointer"
        }
    },
    content: {
        marginLeft: theme.spacing(2),
        padding: "0",
        width: "100%",
    },
    contentTitle: {
        marginBottom: theme.spacing(1)
    }
}))

export const DashboardCardComponent = (props) => {
    const classes = styles()
    let image;
    try {
        image = require('../../../assets/img/' + props.image)
    } catch (e) {
        console.log(e.message)
    }
    return (
        <Card className={classes.root}>
            <CardMedia
                image={image}
                style={{width: "100%", backgroundSize: "contain"}}
            />
            <CardContent className={classes.content}>
                <Typography variant={"h5"} className={classes.contentTitle} component={'p'}>
                    {props.title}
                </Typography>
                <Typography variant={"body2"} component={'p'}>
                    {props.text}
                </Typography>
            </CardContent>
        </Card>
    )

}
