import React from "react";
import {Header} from "../shared/header/Header";
import {useResidentDetailsService} from "./ResidentDetailsService";
import {useSharedStyles} from "../shared/SharedStyles";
import {Spinner} from "../shared/Spinner";
import {StepperFormComponent} from "../shared/stepper_form/StepperFormComponent";
import {Step, StepLabel, Stepper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    stepper: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(0.6)
    },
    stepLabelRoot: {
        "& .MuiStepLabel-active": {
            color: "white"
        },
        "& .MuiStepLabel-completed": {
            color: theme.palette.grey["400"]
        }
    },
    stepLabel: {
        color: theme.palette.grey["200"]
    },
    stepRoot: {
        "& .MuiStepIcon-root.MuiStepIcon-active": {
            color: theme.palette.primary.light
        },
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
            color: theme.palette.success["700"],
            "& > *": {
                color: theme.palette.grey["400"]
            }
        }
    }
}))

export const ResidentDetails = () => {
    const {
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        fields,
        resident,
        isResidentLoading,
        scroll,
        activeStep,
        setActiveStep,
        steps
    } = useResidentDetailsService()

    const sharedClasses = useSharedStyles()
    const classes = useStyles()

    if (fields.length === 0)
        return <Spinner/>

    return (
        <React.Fragment>
            <Header title={headerTitle}
                    subtitle={headerSubtitle}
                    stepperHeader={
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step classes={{
                                        root: classes.stepRoot
                                    }} key={label} {...stepProps}>
                                        <StepLabel classes={{
                                            root: classes.stepLabelRoot,
                                            label: classes.stepLabel
                                        }} {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    }/>
            <div className={sharedClasses.pageContent} id="formComponent">
                {
                    isResidentLoading ? (
                        <Spinner/>
                    ) : (
                        <StepperFormComponent firstPartFormFields={fields.slice(0, 13)}
                                              secondPartFormFields={fields.slice(13, 29)}
                                              thirdPartFormFields={fields.slice(29, 49)}
                                              fourthPartFormFields={fields.slice(49)}
                                              formButtonText={formButtonText}
                                              onSubmit={onFormSubmit}
                                              initialValues={resident}
                                              scroll={scroll}
                                              activeStep={activeStep}
                                              setActiveStep={setActiveStep}/>
                    )
                }
            </div>
        </React.Fragment>
    )
}