import {authorized} from "../../axios";
import {
    CLEANUP_TASKS,
    TASK_CREATE,
    TASK_LOADING,
    TASK_UPDATE,
    TASKS_FETCH_COMMENT,
    TASKS_FETCH_COMMENT_ATTACHMENT,
    TASKS_FETCH_COMMENT_ATTACHMENTS,
    TASKS_FETCH_COMMENTS,
    TASKS_FETCH_TASK,
    TASKS_FETCH_TASK_ATTACHMENT,
    TASKS_FETCH_TASK_ATTACHMENTS,
    TASKS_FETCH_TASKS, TASKS_LOADING
} from "./action.types";
import {errorResponse, successResponse} from "../../components/shared/utils";
import moment from "moment";

const primary_slug = 'tasks/api/v1'

export const TaskActions = {
    fetchTaskAttachment: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/task_attachment/${id}/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_TASK_ATTACHMENT,
                payload: resp.data
            }))
    },

    fetchTaskAttachments: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/task_attachment/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_TASK_ATTACHMENTS,
                payload: resp.data
            }))
    },

    fetchTask: (token, id) => dispatch => {
        const url = `${primary_slug}/task/${id}/`
        dispatch({type: TASK_LOADING})
        authorized(token)
            .get(url)
            .then(resp => {
                resp.data = {
                    ...resp.data,
                }

                return dispatch({
                    type: TASKS_FETCH_TASK,
                    payload: successResponse(resp)
                })
            }).catch(reason => {
            return dispatch({
                type: TASKS_FETCH_TASK,
                payload: errorResponse(reason)
            })
        })
    },

    fetchExpandedTask: (token, id) => dispatch => {
        const url = `${primary_slug}/task/${id}/?expand`
        dispatch({type: TASK_LOADING})
        authorized(token)
            .get(url)
            .then(resp => {
                let tmpObj = {}
                Object.entries(resp.data.assigned_to).forEach(([k, v]) => {
                    tmpObj[`assigned_${k}`] = v
                })
                resp.data = {
                    ...resp.data,
                    ...tmpObj,
                    ...resp.data.created_by
                }

                return dispatch({
                    type: TASKS_FETCH_TASK,
                    payload: successResponse(resp)
                })
            }).catch(reason => {
            return dispatch({
                type: TASKS_FETCH_TASK,
                payload: errorResponse(reason)
            })
        })
    },

    fetchTasks: (token) => dispatch => {
        dispatch({type: TASKS_LOADING})
        authorized(token)
            .get(`${primary_slug}/task/`)
            .then(resp => {
                resp.data.forEach(task => {
                    task.due_date = moment(task.due_date).format('YYYY-MM-DD HH:mm')
                })
                dispatch({
                    type: TASKS_FETCH_TASKS,
                    payload: resp.data
                })
            })
    },

    fetchExpandedTasks: (token) => dispatch => {
        dispatch({type: TASKS_LOADING})
        authorized(token)
            .get(`${primary_slug}/task/?expand`)
            .then(resp => {
                resp.data.forEach(task => {
                    task.due_date = moment(task.due_date).format('YYYY-MM-DD HH:mm')
                })
                dispatch({
                    type: TASKS_FETCH_TASKS,
                    payload: resp.data
                })
            })
    },

    fetchComment: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/comment/${id}/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_COMMENT,
                payload: resp.data
            }))
    },

    fetchComments: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/comment/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_COMMENTS,
                payload: resp.data
            }))
    },

    fetchCommentAttachment: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/comment_attachment/${id}/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_COMMENT_ATTACHMENT,
                payload: resp.data
            }))
    },

    fetchCommentAttachments: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/comment_attachment/`)
            .then(resp => dispatch({
                type: TASKS_FETCH_COMMENT_ATTACHMENTS,
                payload: resp.data
            }))
    },

    createTask: (token, data) => dispatch => {
        authorized(token)
            .post(`${primary_slug}/task/`, data)
            .then(resp => {
                return dispatch({
                    type: TASK_CREATE,
                    payload: successResponse(resp)
                })
            })
            .catch(error => {
                return dispatch({
                    type: TASK_CREATE,
                    payload: errorResponse(error)
                })
            })
    },

    saveTask: (token, id, data) => dispatch => {
        authorized(token)
            .put(`${primary_slug}/task/${id}/`, data)
            .then(resp => {
                return dispatch({
                    type: TASK_UPDATE,
                    payload: successResponse(resp)
                })
            })
            .catch(error => {
                return dispatch({
                    type: TASK_UPDATE,
                    payload: errorResponse(error)
                })
            })
    },

    cleanup: () => dispatch => {
        dispatch({
            type: CLEANUP_TASKS
        })
    }
}