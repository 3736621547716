import {makeStyles} from "@material-ui/styles";

export const useInputStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    icon: {
        color: theme.palette.primary.dark,
        marginRight: theme.spacing(1)
    },
    input: {
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
    },
    inputRoot: {
        // '&$focused $notchedOutline': {
        //     borderColor: 'red'
        // },
        // '&$hover': {
        //     backgroundColor: 'red'
        // }
    }
}))