import React from "react";
import {useTaskDetailsService} from "./TaskDetailsService";
import {useSharedStyles} from "../shared/SharedStyles";
import {Spinner} from "../shared/Spinner";
import {Header} from "../shared/header/Header";
import {FormComponent} from "../shared/forms/FormComponent";

export const TaskDetails = () => {
    const {
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        task,
        fields,
        isTaskLoading
    } = useTaskDetailsService()

    const sharedClasses = useSharedStyles()

    if (fields.length === 0)
        return <Spinner/>

    return (
        <React.Fragment>
            <Header title={headerTitle}
                    subtitle={headerSubtitle}/>
            <div className={sharedClasses.pageContent}>
                {
                    isTaskLoading ? (
                        <Spinner/>
                    ) : (
                        <FormComponent fields={fields}
                                       formButtonText={formButtonText}
                                       onSubmit={onFormSubmit}
                                       initialValues={task}/>
                    )
                }
            </div>
        </React.Fragment>
    )
}