import React from "react";
import {NavbarComponent} from "../components/navigation/Navbar";
import {Container, Grid, Tab, Tabs} from "@material-ui/core";
import {Sidebar} from "../components/navigation/Sidebar";
import {useLayoutStyles} from "./LayoutStyles";
import {useLayoutService} from "./LayoutService";
import {LoginLanding} from "../components/auth/login/LoginLanding";
import {useAuth0} from "@auth0/auth0-react";
import {Spinner} from "../components/shared/Spinner";

export const Layout = (props) => {
    const {isAuthenticated, isLoading} = useAuth0()
    const classes = useLayoutStyles()
    const {
        showTabs,
        activeTab,
        handleTabChange,
        tabNamesList
    } = useLayoutService()

    if (isLoading) {
        return (
            <div className={classes.loading}>
                <Spinner/>
            </div>
        )
    } else if (!isAuthenticated) {
        return <LoginLanding/>
    }

    return (
        <React.Fragment>
            <NavbarComponent/>
            <Container maxWidth={"lg"} className={classes.root}>
                <Grid container className={classes.container}>
                    <Grid item xs={2}>
                        <Sidebar/>
                    </Grid>
                    <Grid item xs={10}
                          className={`${classes.content} ${showTabs ? classes.partialRadius : classes.fullRadius}`}>
                        {props.children}
                    </Grid>

                    {
                        showTabs && !isLoading && (
                            <Tabs value={activeTab}
                                  classes={{
                                      root: classes.tabs
                                  }}
                                  onChange={handleTabChange}
                                  className={classes.tabs}>
                                {
                                    tabNamesList.map(name => (
                                        <Tab label={name}
                                             key={name}
                                             classes={{
                                                 root: classes.tab,
                                                 selected: classes.selected
                                             }}/>
                                    ))
                                }
                            </Tabs>
                        )
                    }

                </Grid>
            </Container>
        </React.Fragment>
    )
}



