import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";
import {INPUT_PASSWORD, INPUT_TEXT} from "./FieldTypes";

export const AuthFormFields = [
    {
        name: "first_name",
        type: INPUT_TEXT,
        isSelection: false,
        label: "First Name",
        helperText: "The person's first name",
        required: false,
        displayed: true,
        icon: <PersonIcon/>,
        gridSpace: 6
    },
    {
        name: "last_name",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Last Name",
        helperText: "The person's last name",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 6
    },
    {
        name: "email",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Email Address",
        helperText: "The person's email address",
        required: false,
        displayed: true,
        icon: <MailIcon/>,
        gridSpace: 12
    },
    {
        name: "password",
        type: INPUT_PASSWORD,
        isSelection: false,
        label: "Password",
        helperText: "The postal code of the city",
        required: false,
        displayed: true,
        icon: <LockIcon/>,
        gridSpace: 6
    },
    {
        name: "confirm_password",
        type: INPUT_PASSWORD,
        isSelection: false,
        label: "Confirm Password",
        helperText: "The postal code of the city",
        required: false,
        displayed: true,
        icon: <LockIcon/>,
        gridSpace: 6
    }
]
