import {
    CLEANUP_TASKS, TASK_CREATE, TASK_LOADING, TASK_UPDATE,
    TASKS_FETCH_COMMENT,
    TASKS_FETCH_COMMENT_ATTACHMENT,
    TASKS_FETCH_COMMENT_ATTACHMENTS,
    TASKS_FETCH_COMMENTS,
    TASKS_FETCH_TASK,
    TASKS_FETCH_TASK_ATTACHMENT,
    TASKS_FETCH_TASK_ATTACHMENTS,
    TASKS_FETCH_TASKS,
    TASKS_LOADING
} from "../actions/action.types";

const initialState = {
    areTasksLoading: false,
    isTaskLoading: false,
    commentDetails: null,
    comments: null,
    commentAttachmentDetails: null,
    commentAttachments: null,
    task: null,
    tasks: null,
    taskAttachmentDetails: null,
    taskAttachments: null,
    taskCreateStatus: null,
    taskCreateMessage: null,
    taskUpdateStatus: null,
    taskUpdateMessage: null
}

export const TaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASKS_LOADING: {
            return {
                ...state,
                areTasksLoading: true,
            }
        }
        case TASK_LOADING: {
            return {
                ...state,
                isTaskLoading: true,
            }
        }
        case TASKS_FETCH_COMMENT:
            return {
                ...state,
                commentDetails: action.payload
            }
        case TASKS_FETCH_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case TASKS_FETCH_COMMENT_ATTACHMENT:
            return {
                ...state,
                commentAttachmentDetails: action.payload
            }
        case TASKS_FETCH_COMMENT_ATTACHMENTS:
            return {
                ...state,
                commentAttachments: action.payload
            }
        case TASKS_FETCH_TASK:
            return {
                ...state,
                isTaskLoading: false,
                task: action.payload.data
            }
        case TASKS_FETCH_TASKS:
            return {
                ...state,
                tasks: action.payload,
                areTasksLoading: false,
            }
        case TASKS_FETCH_TASK_ATTACHMENT:
            return {
                ...state,
                taskAttachmentDetails: action.payload
            }
        case TASKS_FETCH_TASK_ATTACHMENTS:
            return {
                ...state,
                taskAttachments: action.payload
            }
        case TASK_CREATE: {
            return {
                ...state,
                taskCreateStatus: action.payload.status,
                taskCreateMessage: action.payload.message
            }
        }
        case TASK_UPDATE: {
            return {
                ...state,
                taskUpdateStatus: action.payload.status,
                taskUpdateMessage: action.payload.message
            }
        }
        case CLEANUP_TASKS: {
            return initialState
        }
        default:
            return {
                ...state
            }
    }
}