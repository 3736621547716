import {makeStyles} from "@material-ui/core/styles";

export const useLayoutStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden"
    },
    container: {
        position: 'relative',
        marginTop: '12vh',
        height: '85vh',
    },
    content: {
        position: 'relative',
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    fullRadius: {
        borderRadius: theme.spacing(2)
    },
    partialRadius: {
        borderRadius: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    tabs: {
        position: 'absolute',
        right: 0,
        bottom: '100%',
    },
    tab: {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        opacity: 1,
        minWidth: 200,
        borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
    },
    selected: {
        backgroundColor: 'white',
        color: theme.palette.primary.dark,
        fontWeight: 'bold',
    },
    loading: {
        height: '100vh'
    }
}))