import React from "react";
import Card from "@material-ui/core/Card";
import {Avatar, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import {useTableRowStyles} from "../shared/accounts/TableRowStyles";

export const ResidentTableRow = ({resident, cardClickHandler}) => {
    const classes = useTableRowStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => cardClickHandler(resident.id)}>
                <CardContent className={classes.content}>
                    <Grid container spacing={4} alignItems={"center"}>
                        <Grid item xs={1}>
                            <Avatar variant={"circle"}>
                                {resident.full_name[0]}
                            </Avatar>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={"h6"}>
                                {resident.full_name}
                            </Typography>
                            <Typography variant={"body1"}>
                                <span> Last visited: {new Date(resident.last_doctor_visit).toDateString()}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"}>
                                Physician: {resident.physician}
                            </Typography>
                            <Typography variant={"body1"} className={classes.dual}>
                                <PhoneIphoneIcon/>
                                <span> {resident.physician_telephone}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body2"}>
                                Doctor visits: {resident.often_doctor_visit}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
