import {
    CLEANUP_EMPLOYEES,
    EMPLOYEE_LOADING,
    EMPLOYEES_LOADING,
    FETCH_EMPLOYEE,
    FETCH_EMPLOYEES
} from "../actions/action.types";

const initialState = {
    areEmployeesLoading: false,
    isEmployeeLoading: false,
    employees: null,
    employeesFetchStatus: null,
    employeesFetchMessage: null,
    employee: null,
    employeeFetchStatus: null,
    employeeFetchMessage: null,
}

export const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEES_LOADING: {
            return {
                ...state,
                areEmployeesLoading: true,
            }
        }
        case FETCH_EMPLOYEES:
            return {
                ...state,
                areEmployeesLoading: false,
                employees: action.payload.data,
                employeesFetchStatus: action.payload.status,
                employeesFetchMessage: action.payload.message,
            }
        case EMPLOYEE_LOADING: {
            return {
                ...state,
                isEmployeeLoading: true,
            }
        }
        case FETCH_EMPLOYEE:
            return {
                ...state,
                isEmployeeLoading: false,
                employee: action.payload.data,
                employeeFetchStatus: action.payload.status,
                employeeFetchMessage: action.payload.message,
            }
        case CLEANUP_EMPLOYEES: {
            return initialState
        }
        default: {
            return state
        }
    }
}