import React from "react";
import {Spinner} from "../../shared/Spinner";
import {makeStyles} from "@material-ui/styles";
import {Header} from "../../shared/header/Header";
import {useMedicalReportService} from "./MedicalReportService";
import {FormComponent} from "../../shared/forms/FormComponent";
import {useSharedStyles} from "../../shared/SharedStyles";

const useStyle = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        cursor: 'pointer'
    },
    link_fields: {
        display: "flex",
        marginTop: theme.spacing(1),
        width: '40%',
        justifyContent: "space-between"
    }
}))

// const MedicalReport = ({
//                            doctors,
//                            diagnosis,
//                            medical_report,
//                            onReportCreate,
//                            onReportFetch,
//                            onReportUpdate,
//                            onReportClear,
//                            onReportSave,
//                            onDoctorsFetch,
//                            onDiagnosisFetch
//                        }) => {
export const MedicalReport = () => {
    const {
        handleChange,
        handleCreate,
        handleSave,
        handleDoctorClick,
        handleDiagnosisClick,
        config,
        report_id,
        setEditable,
        canEdit,
        history,
        doctors,
        diagnosis,
        medical_report,
        fields
    } = useMedicalReportService()
    const classes = useStyle()
    const sharedClasses = useSharedStyles()

    if (!doctors || !diagnosis || !medical_report || !config)
        return <Spinner/>

    return (
        <React.Fragment>
            <div className={classes.header}>
                <Header title={report_id ? "Medical Report Details" : "Create medical report"}
                        subtitle={report_id ? "View and update medical report" : "Create new medical report"}/>
                {/*{*/}
                {/*    report_id && <FormControlLabel*/}
                {/*        control={*/}
                {/*            <Switch checked={canEdit}*/}
                {/*                    onChange={e => setEditable(e.target.checked)}*/}
                {/*                    name="disabled"*/}
                {/*                    color={'primary'}/>*/}
                {/*        }*/}
                {/*        label="Edit report"*/}
                {/*    />*/}
                {/*}*/}
            </div>
            <div className={sharedClasses.pageContet}>
                <FormComponent fields={fields}
                               formButtonText={"Submit"}
                               onSubmit={handleSave}/>
            </div>
            {/*<Grid container spacing={6}>*/}
            {/*    <Grid item xs={12} md={4}>*/}
            {/*        <FormControl fullWidth placeholder={'Doctor'}>*/}
            {/*            <InputLabel id="doctor-label">Doctor</InputLabel>*/}
            {/*            <Select*/}
            {/*                type={'text'}*/}
            {/*                labelId={'doctor-label'}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={'doctor'}*/}
            {/*                value={medical_report.doctor}*/}
            {/*                fullWidth*/}
            {/*            >*/}
            {/*                {doctors.map(doc => <MenuItem key={doc.id}*/}
            {/*                                              value={doc.id}>{doc.first_name} {doc.last_name}</MenuItem>)}*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        <div className={classes.link_fields}>*/}
            {/*            <Typography variant={'subtitle1'}*/}
            {/*                        color={'primary'}*/}
            {/*                        onClick={() => handleDoctorClick(medical_report.doctor)}*/}
            {/*                        className={classes.link}*/}
            {/*            >*/}
            {/*                View details*/}
            {/*            </Typography>*/}
            {/*            <Typography variant={'subtitle1'}*/}
            {/*                        color={'primary'}*/}
            {/*                        onClick={() => history.push('/doctors/create')}*/}
            {/*                        className={classes.link}*/}
            {/*            >*/}
            {/*                Create new*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}

            {/*<Grid item xs={12} md={4}>*/}
            {/*    <FormControl fullWidth placeholder={'Family Doctor'}>*/}
            {/*        <InputLabel id="fam-doctor-label">Family Doctor</InputLabel>*/}
            {/*        <Select*/}
            {/*            type={'text'}*/}
            {/*            labelId={'fam-doctor-label'}*/}
            {/*            onChange={handleChange}*/}
            {/*            name={'family_doctor'}*/}
            {/*            value={medical_report.family_doctor}*/}
            {/*            fullWidth*/}
            {/*        >*/}
            {/*            {doctors.map(doc => <MenuItem key={doc.id}*/}
            {/*                                          value={doc.id}>{doc.first_name} {doc.last_name}</MenuItem>)}*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*TODO: Add new doctor inline!*/}
            {/*    <div className={classes.link_fields}>*/}
            {/*        <Typography variant={'subtitle1'}*/}
            {/*                    color={'primary'}*/}
            {/*                    onClick={() => handleDoctorClick(medical_report.family_doctor)}*/}
            {/*                    className={classes.link}*/}
            {/*        >*/}
            {/*            View details*/}
            {/*        </Typography>*/}
            {/*        <Typography variant={'subtitle1'}*/}
            {/*                    color={'primary'}*/}
            {/*                    onClick={() => history.push('/doctors/create')}*/}
            {/*                    className={classes.link}*/}
            {/*        >*/}
            {/*            Create new*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*</Grid>*/}

            {/*    <Grid item xs={12} md={4}>*/}
            {/*        <FormControl fullWidth placeholder={'Diagnosis'}>*/}
            {/*            <InputLabel id="diagnosis-label">Diagnosis</InputLabel>*/}
            {/*            <Select*/}
            {/*                type={'text'}*/}
            {/*                labelId={'diagnosis-label'}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={'diagnosis'}*/}
            {/*                value={medical_report.diagnosis}*/}
            {/*                fullWidth*/}
            {/*            >*/}
            {/*                {diagnosis.map(g => <MenuItem key={g.id}*/}
            {/*                                              value={g.id}>{g.id}</MenuItem>)}*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*TODO: Add inline diagnosis! */}
            {/*        <div className={classes.link_fields}>*/}
            {/*            <Typography variant={'subtitle1'}*/}
            {/*                        color={'primary'}*/}
            {/*                        onClick={handleDiagnosisClick}*/}
            {/*                        className={classes.link}*/}
            {/*            >*/}
            {/*                View details*/}
            {/*            </Typography>*/}
            {/*            <Typography variant={'subtitle1'}*/}
            {/*                        color={'primary'}*/}
            {/*                        onClick={() => history.push('/diagnosis/create')}*/}
            {/*                        className={classes.link}*/}
            {/*            >*/}
            {/*                Create new*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}

            {/*    {*/}
            {/*        config.fields.residents.medical_report.height && <Grid item xs={12} md={4}>*/}
            {/*            <TextField*/}
            {/*                label="Height"*/}
            {/*                helperText="The patients height in centimeters"*/}
            {/*                fullWidth*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                defaultValue={0}*/}
            {/*                type={'number'}*/}
            {/*                value={medical_report.height}*/}
            {/*                name={"height"}*/}
            {/*                onChange={handleChange}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.weight && <Grid item xs={12} md={4}>*/}
            {/*            <TextField*/}
            {/*                label="Weight"*/}
            {/*                helperText="The patients weight in kilograms"*/}
            {/*                fullWidth*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                defaultValue={0}*/}
            {/*                type={'number'}*/}
            {/*                value={medical_report.weight}*/}
            {/*                name={"weight"}*/}
            {/*                onChange={handleChange}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.regimen && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Regimen"*/}
            {/*                helperText="Detailed explanation of any resident regimen"*/}
            {/*                placeholder="Regimen"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.regimen}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"regimen"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.immunization && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Immunization"*/}
            {/*                helperText="Detailed explanation of resident's immunization"*/}
            {/*                placeholder="immunization"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.immunization}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"immunization"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.allergies && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Allergies"*/}
            {/*                helperText="Detailed explanation of resident's allergies"*/}
            {/*                placeholder="Allergies"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.allergies}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"allergies"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.hospitalizations && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Hospitalizations"*/}
            {/*                helperText="Detailed explanation of resident's hospitalizations"*/}
            {/*                placeholder="Hospitalizations"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.hospitalizations}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"hospitalizations"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.physician_notes && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Physician notes"*/}
            {/*                helperText="Place for leaving physician notes"*/}
            {/*                placeholder="Physician notes"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.physician_notes}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"physician_notes"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.heent && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Heent????"*/}
            {/*                helperText="ASK"*/}
            {/*                placeholder="ASK"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.heent}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"heent"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.respiratory && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Respiratory state"*/}
            {/*                helperText="Resident's respiratory state"*/}
            {/*                placeholder="Respiratory state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.respiratory}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"respiratory"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.cardiac && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Cardiac state"*/}
            {/*                helperText="Resident's cardiac state"*/}
            {/*                placeholder="Cardiac state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.cardiac}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"cardiac"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.vascular && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Vascular state"*/}
            {/*                helperText="Resident's vascular state"*/}
            {/*                placeholder="Vascular state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.vascular}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"vascular"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.gastro_intestinal && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Gastro-intestinal state"*/}
            {/*                helperText="Resident's gastro-intestinal state"*/}
            {/*                placeholder="Gastro-intestinal state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.gastro_intestinal}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"gastro_intestinal"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.genitourinary && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Genitourinary state"*/}
            {/*                helperText="Resident's genitourinary state"*/}
            {/*                placeholder="Genitourinary state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.genitourinary}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"genitourinary"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.neuromuscular && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Neuromuscular state"*/}
            {/*                helperText="Resident's neuromuscular state"*/}
            {/*                placeholder="Neuromuscular state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.neuromuscular}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"neuromuscular"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.emotional && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Emotional state"*/}
            {/*                helperText="Resident's emotional state"*/}
            {/*                placeholder="Emotional state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.emotional}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"emotional"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.hematological && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Hematological state"*/}
            {/*                helperText="Resident's hematological state"*/}
            {/*                placeholder="Hematological state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.hematological}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"hematological"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.rheumatic && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Rheumatic state"*/}
            {/*                helperText="Resident's rheumatic state"*/}
            {/*                placeholder="Rheumatic state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.rheumatic}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"rheumatic"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.endocrine && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Endocrine state"*/}
            {/*                helperText="Resident's endocrine state"*/}
            {/*                placeholder="Endocrine state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.endocrine}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"endocrine"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.dermatological && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Dermatological state"*/}
            {/*                helperText="Resident's dermatological state"*/}
            {/*                placeholder="Dermatological state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.dermatological}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"dermatological"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.dietary_restrictions && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Dietary restrictions"*/}
            {/*                helperText="Resident's dietary restrictions"*/}
            {/*                placeholder="Dietary restrictions"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.dietary_restrictions}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"dietary_restrictions"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.occupation && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Occupation"*/}
            {/*                helperText="Resident's occupation"*/}
            {/*                placeholder="Occupation"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.occupation}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"occupation"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.habits && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Habits"*/}
            {/*                helperText="Resident's habits"*/}
            {/*                placeholder="Habits"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.habits}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"habits"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.present_environment && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Present environment"*/}
            {/*                helperText="Resident's present environment"*/}
            {/*                placeholder="Present environment"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.present_environment}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"present_environment"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.financial && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Financial state"*/}
            {/*                helperText="Resident's financial state"*/}
            {/*                placeholder="Financial state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.financial}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"financial"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.psychosocial && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Psychosocial state"*/}
            {/*                helperText="Resident's psychosocial state"*/}
            {/*                placeholder="Psychosocial state"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.psychosocial}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"psychosocial"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.general && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="General information"*/}
            {/*                helperText="Additional relevant information about the resident"*/}
            {/*                placeholder="General information"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.general}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"general"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        config.fields.residents.medical_report.illnesses && <Grid item xs={12} md={6}>*/}
            {/*            <TextField*/}
            {/*                label="Illnesses"*/}
            {/*                helperText="Additional relevant information about the resident's illnesses"*/}
            {/*                placeholder="Illnesses"*/}
            {/*                fullWidth*/}
            {/*                multiline*/}
            {/*                rows={12}*/}
            {/*                margin="normal"*/}
            {/*                InputLabelProps={{*/}
            {/*                    shrink: true,*/}
            {/*                }}*/}
            {/*                value={medical_report.illnesses}*/}
            {/*                onChange={handleChange}*/}
            {/*                name={"illnesses"}*/}
            {/*                variant={'outlined'}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    }*/}
            {/*    <Grid item xs={12}>*/}
            {/*        {*/}
            {/*            !report_id && <Button color={'secondary'}*/}
            {/*                                  size={'large'}*/}
            {/*                                  variant={'contained'}*/}
            {/*                                  onClick={handleCreate}*/}
            {/*            >*/}
            {/*                Create Medical Report*/}
            {/*            </Button>*/}
            {/*        }*/}
            {/*        {*/}
            {/*            canEdit && report_id && <Button color={'secondary'}*/}
            {/*                                             size={'large'}*/}
            {/*                                             variant={'contained'}*/}
            {/*                                             onClick={handleSave}*/}
            {/*            >*/}
            {/*                Save Medical Report*/}
            {/*            </Button>*/}
            {/*        }*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </React.Fragment>
    )
}

// const mapStateToProps = store => {
//     return {
//         doctors: store.medications.doctors,
//         diagnosis: store.medications.diagnosis,
//         medical_report: store.residents.medical_report_details
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         onReportCreate: (token, data) => dispatch(ResidentActions.createReport(token, data)),
//         onReportFetch: (token, id) => dispatch(ResidentActions.fetchMedicalReport(token, id)),
//         onReportUpdate: (name, value) => dispatch(ResidentActions.updateReportDetails(name, value)),
//         onReportSave: (token, id, data) => dispatch(ResidentActions.saveReport(token, id, data)),
//         onReportClear: () => dispatch(ResidentActions.clearReportDetails()),
//         onDoctorsFetch: (token) => dispatch(actions.fetchDoctors(token)),
//         onDiagnosisFetch: (token) => dispatch(actions.fetchAllDiagnosis(token))
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(MedicalReport)
