import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useAuth0} from "@auth0/auth0-react";
import Logo from "../../assets/logos/SVG/Carrie_Logo_dekemvri 2020-10.svg";
import PersonIcon from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import {Container, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appBarRoot: {
        backgroundColor: "transparent",
    },
    appBarImage: {
        height: '50px',
        marginRight: 'auto'
    },
    appBarLinks: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    appBarLinkText: {
        color: "white",
        textTransform: "uppercase",
        textDecoration: "none",
        cursor: 'pointer',
    },
    appBarProfileBtn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: theme.spacing(1)
    },
}))

export const NavbarComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const {isAuthenticated, logout, user} = useAuth0();

    return (
        <React.Fragment>
            <AppBar className={classes.appBarRoot} position={'fixed'} elevation={0}>
                <Container maxWidth={'lg'}>
                    <Toolbar style={{padding: 0}}>
                        <img src={Logo} alt="Carrie" className={classes.appBarImage}/>
                        {
                            isAuthenticated && (
                                <div className={classes.appBarLinks}>
                                    <Button onClick={() => history.push('/profile')}
                                            startIcon={<PersonIcon/>}
                                            className={`${classes.appBarProfileBtn} ${classes.appBarLinkText}`}>
                                        <Typography variant={'body2'}>
                                            {user.name}
                                        </Typography>
                                    </Button>
                                    <Button className={classes.appBarLinkText}
                                            startIcon={<MeetingRoomIcon/>}
                                            onClick={() => logout({returnTo: window.location.origin})}>
                                        <Typography variant={'body2'}>
                                            Logout
                                        </Typography>
                                    </Button>
                                </div>
                            )
                        }
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    )
}
