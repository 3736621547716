import React from "react";
import {Header} from "../shared/header/Header";
import {useEmployeeDetailsService} from "./EmployeeDetailsService";
import {useSharedStyles} from "../shared/SharedStyles";
import {FormComponent} from "../shared/forms/FormComponent";
import {Spinner} from "../shared/Spinner";

export const EmployeeDetails = () => {
    const {
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        fields,
        employee,
        isEmployeeLoading
    } = useEmployeeDetailsService()

    const sharedClasses = useSharedStyles()

    if (fields.length === 0)
        return <Spinner/>

    return (
        <React.Fragment>
            <Header title={headerTitle}
                    subtitle={headerSubtitle}/>
            <div className={sharedClasses.pageContent}>
                {
                    isEmployeeLoading ? (
                        <Spinner/>
                    ) : (
                        <FormComponent fields={fields}
                                       formButtonText={formButtonText}
                                       onSubmit={onFormSubmit}
                                       initialValues={employee}/>
                    )
                }
            </div>
        </React.Fragment>
    )
}