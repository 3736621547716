import React from "react";
import {Avatar, Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailIcon from "@material-ui/icons/Mail";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import HomeIcon from "@material-ui/icons/Home";
import {useTableRowStyles} from "../shared/accounts/TableRowStyles";

export const GuardianTableRow = ({guardian, cardClickHandler}) => {
    const classes = useTableRowStyles()

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => cardClickHandler(guardian.id)}>
                <CardContent className={classes.content}>
                    <Grid container spacing={4} alignItems={"center"}>
                        <Grid item xs={1}>
                            <Avatar variant={"circle"}>
                                {guardian.auth_user.first_name[0]}
                            </Avatar>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant={"h6"}>{guardian.auth_user.first_name} {guardian.auth_user.last_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"} className={classes.dual}>
                                <PhoneIphoneIcon/>
                                <span>{guardian.mobile_phone}</span>
                            </Typography>
                            <Typography variant={"body1"} className={classes.dual}>
                                <MailIcon/>
                                <span>{guardian.auth_user.email}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"} className={classes.dual}>
                                <LocationCityIcon/>
                                <span>{guardian.city}</span>
                            </Typography>
                            <Typography variant={"body1"} className={classes.dual}>
                                <HomeIcon/>
                                <span>{guardian.address_1}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}