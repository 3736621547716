import {useAuth0} from "@auth0/auth0-react";
import {useFormUtils} from "../shared/forms/FormUtils";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {ResidentActions} from "../../store/actions/ResidentActions";
import {ResidentFields} from "../shared/fields/ResidentFields";
import {ResponseStatus} from "../shared/Constants";
import {GuardianActions} from "../../store/actions/GuardianActions";

export const useResidentDetailsService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const {fieldsToRequestObject} = useFormUtils()
    const {id} = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const [activeStep, setActiveStep] = useState(0);
    const {
        resident,
        residentFetchStatus,
        residentFetchMessage,
        isResidentLoading,
        residentCreateStatus,
        residentCreateMessage,
        residentUpdateStatus,
        residentUpdateMessage
    } = useSelector(state => state.residents)

    const guardians = useSelector(state => state.guardians.guardians)
    const steps = ['General personal information', 'Health center information',
        'Additional personal and visit information', 'Hobbies, activities and interests'];

    const headerTitle = id ? 'Resident details' : 'Create resident'
    const headerSubtitle = id ? 'View and edit resident details' : 'Enter resident data'
    const formButtonText = id ? 'Save resident' : 'Create resident'
    const [fields, setFields] = useState([])

    const onFormSubmit = (formData) => {
        const request = fieldsToRequestObject(fields, formData)
        if (id) {
            getAccessTokenSilently().then(token => {
                dispatch(ResidentActions.saveResident(token, id, request))
            })
        } else {
            getAccessTokenSilently().then(token => {
                dispatch(ResidentActions.createResident(token, request))
            })
        }
    }

    const pushHistory = () => {
        if (residentCreateStatus === ResponseStatus.SUCCESS || residentUpdateStatus === ResponseStatus.SUCCESS) {
            history.push("/residents")
            dispatch(ResidentActions.cleanup())
        } else if (residentCreateStatus === ResponseStatus.ERROR) {
            alert(residentCreateMessage)
            dispatch(ResidentActions.updateResidentRequest({}, true))
        } else if (residentUpdateStatus === ResponseStatus.ERROR) {
            alert(residentUpdateMessage)
            dispatch(ResidentActions.updateResidentRequest({}, true))
        }
    }

    useEffect(pushHistory, [residentCreateStatus, residentCreateMessage, residentUpdateStatus, residentUpdateMessage])

    const scroll = () => {
        document.getElementById("formComponent").scrollTo(0, 0)
    }

    const setupFields = useCallback(() => {
        if (guardians) {
            const options = guardians.map((guardian, idx) => (
                {
                    name: guardian.id,
                    label: `${guardian.auth_user.first_name} ${guardian.auth_user.last_name}`,
                    checked: idx === 0 // select the first guardian as the default
                }
            ))

            const newFields = []
            for (const field of ResidentFields) {
                if (field.name === 'guardian') {
                    field.options = options
                }
                newFields.push(field)
            }
            setFields(() => newFields)
        }


        return () => {
            GuardianActions.cleanup()
        }
    }, [guardians])

    const fetchData = useCallback(() => {
        getAccessTokenSilently().then(token => {
            dispatch(GuardianActions.fetchGuardians(token))
        })

        if (id) {
            getAccessTokenSilently().then(token => {
                dispatch(ResidentActions.fetchResident(token, id))
            })
        }

        return () => {
            dispatch(ResidentActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently, id])

    const handleError = useCallback(() => {
        if (residentFetchStatus === ResponseStatus.ERROR) {
            alert(residentFetchMessage)
        }
    }, [residentFetchMessage, residentFetchStatus])

    useEffect(fetchData, [id])
    useEffect(setupFields, [guardians])
    useEffect(handleError, [residentFetchStatus])

    return {
        fields,
        headerTitle,
        formButtonText,
        headerSubtitle,
        onFormSubmit,
        resident,
        isResidentLoading,
        scroll,
        steps,
        activeStep,
        setActiveStep
    }
}