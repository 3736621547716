import React from "react";
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {HeaderBreadcrumbs} from "./HeaderBreadcrumbs";
import {useSelector} from "react-redux";
import {ViewList, ViewDay} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    partialRadius: {
        borderRadius: `${theme.spacing(2)}px 0 0 0`,
    },
    text: {
        width: '100%',
        margin: theme.spacing(2, 3),
    },
    button: {
        backgroundColor: '#40cbed',
        color: 'white',
        margin: 'auto',
    },
    buttonWide: {
        width: 210
    },
    iconMargin: {
        marginRight: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: 24
    },
    alignButtons: {
        alignItems: "end",
        "& > *": {
            marginRight: theme.spacing(2)
        }
    }

}))

export const Header = ({
                           title,
                           subtitle,
                           buttonAction,
                           buttonText,
                           buttonIcon,
                           buttonDenseViewAction,
                           denseView,
                           stepperHeader
                       }) => {
    const classes = useStyles()
    const tabNameList = useSelector(state => state.ui.tabNamesList)

    return (
        <div className={`${classes.root} ${tabNameList && classes.partialRadius}`}>
            <div>
                <div className={classes.text}>
                    <HeaderBreadcrumbs/>
                </div>
                {
                    stepperHeader ??
                    <div className={classes.text}>
                        <Typography variant={'h5'}>
                            {title}
                        </Typography>
                        <Typography variant={'body1'}>
                            {subtitle}
                        </Typography>
                    </div>
                }
            </div>
            <div className={classes.alignButtons}>
                {
                    buttonAction && buttonText && (
                        <Button onClick={buttonAction}
                                startIcon={buttonIcon}
                                className={[classes.button, classes.buttonWide].join(" ")}>
                            {buttonText}
                        </Button>
                    )
                }
                {
                    buttonDenseViewAction && (
                        denseView ? (
                            <Button onClick={buttonDenseViewAction}
                                    startIcon={<ViewDay/>}
                                    className={classes.button}
                                    classes={{startIcon: classes.iconMargin}}/>
                        ) : (
                            <Button onClick={buttonDenseViewAction}
                                    startIcon={<ViewList/>}
                                    className={classes.button}
                                    classes={{startIcon: classes.iconMargin}}/>
                        )
                    )
                }
            </div>
        </div>
    )
}