import React from "react";
import {INPUT_NUMBER, INPUT_TEXT, SELECTION_SINGLE} from "./FieldTypes";

export const MedicalReportFields = [
    {
        name: "doctor",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Doctor",
        helperText: "Choose the doctor",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "family_doctor",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Family doctor",
        helperText: "Choose the family doctor",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        //TODO: Remove this field in the future due to architecture
        name: "diagnosis",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Patient's diagnosis",
        helperText: "Choose the diagnosis for the patient",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 12
    },
    {
        name: "height",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "Height",
        helperText: "The patient's height in centimeters",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "weight",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "Weight",
        helperText: "The patient's weight in kilograms",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "regimen",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Regimen",
        helperText: "Detailed explanation of any resident regimen",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "immunization",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Immunization",
        helperText: "Detailed explanation of resident's immunization",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "allergies",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Allergies",
        helperText: "Detailed explanation of resident's allergies",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "hospitalizations",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Hospitalizations",
        helperText: "Detailed explanation of resident's hospitalizations",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "physician_notes",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Physician notes",
        helperText: "Place for leaving physician notes",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "heent",
        type: INPUT_TEXT,
        isSelection: false,
        label: "HEENT exam",
        helperText: "Detailed explanation of resident's HEENT (head, eyes, ears, nose and throat) exam",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "respiratory",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Respiratory state",
        helperText: "Resident's respiratory state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "cardiac",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Cardiac state",
        helperText: "Resident's cardiac state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "vascular",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Vascular state",
        helperText: "Resident's vascular state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "gastro_intestinal",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Gastro-intestinal state",
        helperText: "Resident's gastro-intestinal state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "genitourinary",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Genitourinary state",
        helperText: "Resident's genitourinary state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "neuromuscular",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Neuromuscular state",
        helperText: "Resident's neuromuscular state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "emotional",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Emotional state",
        helperText: "Resident's emotional state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "hematological",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Hematological state",
        helperText: "Resident's hematological state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "rheumatic",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Rheumatic state",
        helperText: "Resident's rheumatic state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "endocrine",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Endocrine state",
        helperText: "Resident's endocrine state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "dermatological",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Dermatological state",
        helperText: "Resident's dermatological state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "dietary_restrictions",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Dietary restrictions",
        helperText: "Resident's dietary restrictions",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "occupation",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Occupation",
        helperText: "Resident's occupation",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "habits",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Habits",
        helperText: "Resident's habits",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "present_environment",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Present environment",
        helperText: "Resident's present environment",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "financial",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Financial state",
        helperText: "Resident's financial state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "psychosocial",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Psychosocial state",
        helperText: "Resident's psychosocial state",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "general",
        type: INPUT_TEXT,
        isSelection: false,
        label: "General information",
        helperText: "Additional relevant information about the resident",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "illnesses",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Illnesses",
        helperText: "Additional relevant information about the resident's illnesses",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    }
]