import React from "react";
import {useStepperFormComponentService} from "./StepperFormComponentService";

export const StepperFormComponent = ({
                                         firstPartFormFields,
                                         secondPartFormFields,
                                         thirdPartFormFields,
                                         fourthPartFormFields,
                                         onSubmit,
                                         initialValues,
                                         scroll,
                                         activeStep,
                                         setActiveStep
                                     }) => {
    const {
        getStepContent
    } = useStepperFormComponentService({
        firstPartFormFields,
        secondPartFormFields,
        thirdPartFormFields,
        fourthPartFormFields,
        onSubmit,
        initialValues,
        scroll,
        activeStep,
        setActiveStep
    })

    return (
        <React.Fragment>
            {getStepContent(activeStep)}
        </React.Fragment>
    );
}