import React from "react";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import WcIcon from "@material-ui/icons/Wc";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import EventIcon from "@material-ui/icons/Event";
import StoreIcon from "@material-ui/icons/Store";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import {INPUT_BOOLEAN, INPUT_DATE, INPUT_NUMBER, INPUT_TEXT, SELECTION_MULTIPLE, SELECTION_SINGLE} from "./FieldTypes";
import {AuthUserPositionOptions, EmployeePaymentTypeOptions, GenderOptions} from "./SelectOptions";

export const EmployeeFormFields = [
    {
        name: "status",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Employee Status",
        helperText: "The employee's status",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 3
    },
    {
        name: "positions",
        type: SELECTION_MULTIPLE,
        options: AuthUserPositionOptions,
        isSelection: true,
        label: "Select auth user positions",
        helperText: "Choosing the right roles sets the user's access across the application",
        required: false,
        displayed: true,
        icon: <LockOpenIcon/>,
        gridSpace: 12
    },
    {
        name: "gender",
        type: SELECTION_SINGLE,
        options: GenderOptions,
        isSelection: true,
        label: "Employee's gender",
        helperText: "Select the employee's gender",
        required: false,
        displayed: true,
        icon: <WcIcon/>,
        gridSpace: 6
    },
    {
        name: "employee_type",
        type: SELECTION_SINGLE,
        options: EmployeePaymentTypeOptions,
        isSelection: true,
        label: "Select the employee payment type",
        helperText: "Choose the employee's payment agreement",
        required: false,
        displayed: true,
        icon: <PermContactCalendar/>,
        gridSpace: 6
    },
    {
        name: "date_of_birth",
        type: INPUT_DATE,
        label: "The employee's date of birth",
        helperText: "Pick the date the employee was born",
        required: false,
        displayed: true,
        icon: <EventIcon/>,
        gridSpace: 6
    },
    {
        name: "department",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Department",
        helperText: "The person's work department",
        required: false,
        displayed: true,
        icon: <StoreIcon/>,
        gridSpace: 6
    },
    {
        name: "work_hours",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "Work hours",
        helperText: "The employee's work hours per day",
        required: false,
        displayed: true,
        icon: <EventIcon/>,
        gridSpace: 6
    },
    {
        name: "hire_date",
        type: INPUT_DATE,
        label: "The employee's hire date",
        helperText: "Pick the date the employee was hired",
        required: false,
        displayed: true,
        icon: <WatchLaterIcon/>,
        gridSpace: 6
    },
    {
        name: "mobile_phone",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Phone number",
        helperText: "The employee's mobile phone number",
        required: false,
        displayed: true,
        icon: <PhoneAndroidIcon/>,
        gridSpace: 12
    },
]
