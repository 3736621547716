import {CLEANUP_EMPLOYEES, EMPLOYEE_LOADING, EMPLOYEES_LOADING, FETCH_EMPLOYEE, FETCH_EMPLOYEES} from "./action.types";
import {authorized} from "../../axios";
import {errorResponse, successResponse} from "../../components/shared/utils";

const primary_slug = 'accounts/api/v1'

export const EmployeeActions = {

    fetchEmployee: (token, id) => dispatch => {
        const url = `${primary_slug}/employee/${id}/`
        dispatch({type: EMPLOYEE_LOADING})
        authorized(token).get(url).then(resp => {
            resp.data = {
                ...resp.data,
                ...resp.data.auth_user
            }
            return dispatch({
                type: FETCH_EMPLOYEE,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_EMPLOYEE,
                payload: errorResponse(reason)
            })
        })
    },

    fetchEmployees: (token, positionQuery) => dispatch => {
        let url = `${primary_slug}/employee/`
        if (positionQuery) {
            url += `?position=${positionQuery}`
        }
        dispatch({type: EMPLOYEES_LOADING})
        authorized(token).get(url).then(resp => {
            return dispatch({
                type: FETCH_EMPLOYEES,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_EMPLOYEES,
                payload: errorResponse(reason)
            })
        })
    },

    cleanup: () => dispatch => {
        dispatch({
            type: CLEANUP_EMPLOYEES
        })
    }
}