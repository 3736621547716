import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import AssignmentIcon from "@material-ui/icons/Assignment";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getConfig} from "../../configs/config_loader";
import {Spinner} from "../shared/Spinner";
import {useAuth0} from "@auth0/auth0-react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    linkList: {
        padding: 0,
        paddingTop: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
    },
    active: {
        '& *': {
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
        },
        '& .triangle': {
            borderRight: "12px solid white",
        }
    },
    listItem: {
        color: 'white'
    },
    listItemWrapper: {
        borderRadius: '30px 0 0 30px',
        paddingRight: 0
    },
    triangle: {
        width: 0,
        height: 0,
        borderTop: "12px solid transparent",
        borderBottom: "12px solid transparent",
        borderRight: "12px solid transparent",
    }
}))

export const Sidebar = () => {
    const classes = useStyles()
    const [config, set_config] = useState(null)
    const {isAuthenticated} = useAuth0();

    useEffect(() => {
        getConfig().then(set_config)
    }, [])

    if (!config) {
        return <Spinner/>
    }
    return (
        isAuthenticated && (
            <List className={classes.linkList}>
                {
                    links.filter(link => config.modules.indexOf(link.module) !== -1)
                        .map((l, index) => {
                                return (
                                    <NavLink exact={index === 0}
                                             to={l.to}
                                             className={classes.link}
                                             key={l.text}
                                             activeClassName={classes.active}>
                                        <ListItem button
                                                  id={l.text}
                                                  className={`list-item ${classes.listItemWrapper}`}>
                                            <ListItemIcon className={classes.listItem}>
                                                {l.icon}
                                            </ListItemIcon>
                                            <ListItemText className={classes.listItem} primary={l.text}/>
                                            <div className={`${classes.triangle} triangle`}/>
                                        </ListItem>
                                    </NavLink>
                                )
                            }
                        )
                }
            </List>
        )
    )
}

const links = [
    {
        icon: <DashboardIcon/>,
        to: '/',
        text: 'Dashboard',
        module: 'dashboard'
    },
    {
        icon: <SupervisorAccountIcon/>,
        to: '/employees',
        text: 'Employees',
        module: 'accounts'
    },
    {
        icon: <SupervisorAccountIcon/>,
        to: '/guardians',
        text: 'Guardians',
        module: 'accounts'
    },
    {
        icon: <AvTimerIcon/>,
        to: '/events',
        text: 'Events',
        module: 'events'
    },
    {
        icon: <LocalHospitalIcon/>,
        to: '/medications',
        text: 'Medications',
        module: 'medications'
    },
    {
        icon: <FolderSharedIcon/>,
        to: '/residents',
        text: 'Residents',
        module: 'residents'
    },
    {
        icon: <AssignmentIcon/>,
        to: '/tasks',
        text: 'Tasks',
        module: 'tasks'
    }
]
