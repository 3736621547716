import React from "react";
import PinDropIcon from "@material-ui/icons/PinDrop";
import RoomIcon from "@material-ui/icons/Room";
import PublicIcon from "@material-ui/icons/Public";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import {INPUT_TEXT} from "./FieldTypes";

export const AddressFields = [
    {
        name: "address_1",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Address 1",
        helperText: "The person's place of living",
        required: false,
        displayed: true,
        icon: <PinDropIcon/>,
        gridSpace: 6
    },
    {
        name: "address_2",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Address 2",
        helperText: "The person's place of living",
        required: false,
        displayed: true,
        icon: <RoomIcon/>,
        gridSpace: 6
    },
    {
        name: "address_3",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Address 3",
        helperText: "The person's place of living",
        required: false,
        displayed: true,
        icon: <RoomIcon/>,
        gridSpace: 6
    },
    {
        name: "address_4",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Address 4",
        helperText: "The person's place of living",
        required: false,
        displayed: true,
        icon: <RoomIcon/>,
        gridSpace: 6
    },
    {
        name: "country",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Country",
        helperText: "The country the person is living in",
        required: false,
        displayed: true,
        icon: <PublicIcon/>,
        gridSpace: 4
    },
    {
        name: "city",
        type: INPUT_TEXT,
        isSelection: false,
        label: "City",
        helperText: "The city the person is living in",
        required: false,
        displayed: true,
        icon: <LocationCityIcon/>,
        gridSpace: 4
    },
    {
        name: "postal",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Postal Code",
        helperText: "The postal code of the city",
        required: false,
        displayed: true,
        icon: <ContactMailIcon/>,
        gridSpace: 4
    }
]
