import {authorized} from "../../axios";
import {
    MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS,
    MEDICATIONS_CLEAR_DOCTOR_DETAILS,
    MEDICATIONS_FETCH_ALL_DIAGNOSIS,
    MEDICATIONS_FETCH_DIAGNOSIS,
    MEDICATIONS_FETCH_DOCTOR,
    MEDICATIONS_FETCH_DOCTORS,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES,
    MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS,
    MEDICATIONS_FETCH_MEDICATION,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULE,
    MEDICATIONS_FETCH_MEDICATION_SCHEDULES,
    MEDICATIONS_FETCH_MEDICATIONS,
    MEDICATIONS_FETCH_THERAPIES,
    MEDICATIONS_FETCH_THERAPY,
    MEDICATIONS_FETCH_THERAPY_SCHEDULE,
    MEDICATIONS_FETCH_THERAPY_SCHEDULES, MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS,
    MEDICATIONS_UPDATE_DOCTOR_DETAILS
} from "./action.types";

const primary_slug = 'medications/api/v1'

export const MedicationActions = {
    fetchMedicalExaminationType: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_examination_type/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES,
                payload: resp.data
            }))
    },

    fetchMedicalExaminationTypes: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_examination_type/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE,
                payload: resp.data
            }))
    },

    fetchDoctor: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/doctor/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_DOCTOR,
                payload: resp.data
            }))
    },

    fetchDoctors: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/doctor/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_DOCTORS,
                payload: resp.data
            }))
    },

    fetchTherapy: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/therapy/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_THERAPY,
                payload: resp.data
            }))
    },

    fetchTherapies: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/therapy/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_THERAPIES,
                payload: resp.data
            }))
    },

    fetchDiagnosis: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/diagnosis/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_DIAGNOSIS,
                payload: resp.data
            }))
    },

    fetchAllDiagnosis: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/diagnosis/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_ALL_DIAGNOSIS,
                payload: resp.data
            }))
    },

    fetchMedication: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medication/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICATION,
                payload: resp.data
            }))
    },

    fetchMedications: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medication/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICATIONS,
                payload: resp.data
            }))
    },

    fetchMedicalExamination: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_examination/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICAL_EXAMINATION,
                payload: resp.data
            }))
    },

    fetchMedicalExaminations: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_examination/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS,
                payload: resp.data
            }))
    },

    fetchMedicationSchedule: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medication_schedule/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICATION_SCHEDULE,
                payload: resp.data
            }))
    },

    fetchMedicationSchedules: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medication_schedule/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_MEDICATION_SCHEDULES,
                payload: resp.data
            }))
    },

    fetchTherapySchedule: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/therapy_schedule/${id}/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_THERAPY_SCHEDULE,
                payload: resp.data
            }))
    },

    fetchTherapySchedules: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/therapy_schedule/`)
            .then(resp => dispatch({
                type: MEDICATIONS_FETCH_THERAPY_SCHEDULES,
                payload: resp.data
            }))
    },

    updateDoctorDetails: (name, value) => dispatch => {
        dispatch({
            type: MEDICATIONS_UPDATE_DOCTOR_DETAILS,
            payload: {name, value}
        })
    },

    clearDoctorDetails: () => dispatch => {
        dispatch({
            type: MEDICATIONS_CLEAR_DOCTOR_DETAILS,
        })
    },

    createDoctor: (token, data) => dispatch => {
        authorized(token)
            .post(`${primary_slug}/doctor/`, data)
            .then(resp => alert('Success'))
            .catch(alert)
    },

    updateDiagnosisDetails: (name, value) => dispatch => {
        dispatch({
            type: MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS,
            payload: {name, value}
        })
    },

    clearDiagnosisDetails: () => dispatch => {
        dispatch({
            type: MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS,
        })
    },

    createDiagnosis: (token, data) => dispatch => {
        authorized(token)
            .post(`${primary_slug}/diagnosis/`, data)
            .then(resp => alert('Success'))
            .catch(alert)
    },

    saveDiagnosis: (token, id, data) => dispatch => {
        authorized(token)
            .put(`${primary_slug}/diagnosis/${id}/`, data)
            .then(resp => alert('Success'))
            .catch(alert)
    },

    saveDoctor: (token, id, data) => dispatch => {
        authorized(token)
            .put(`${primary_slug}/doctor/${id}/`, data)
            .then(resp => alert('Success'))
            .catch(alert)
    }
}