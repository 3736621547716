import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {makeStyles} from "@material-ui/styles";
import {Header} from "../shared/header/Header";

const useStyles = makeStyles(theme => ({
    rootBreadcrumbs: {
        '& > * + *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }
}))
const Profile = () => {
    const {isAuthenticated, user} = useAuth0()
    const classes = useStyles();
    return (
        <div className="profile">
            <Header title={'Profile'}
                    subtitle={'View your details'}/>
            {isAuthenticated && (

                <div>
                    <img src={user.picture} alt={user.name}/>
                    <h2 style={{color: "#2a85d26b"}}>{user.name}</h2>
                    <p style={{color: "#2a85d26b"}}>{user.email}</p>
                </div>
            )
            }
            <div style={{marginTop: '200px'}}>{isAuthenticated}</div>
        </div>
    );
}

export default Profile
