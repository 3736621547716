import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {SELECTION_MULTIPLE, SELECTION_SINGLE} from "../fields/FieldTypes";

const useStyles = makeStyles((theme) => ({
    root: {},
    iconLabel: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}))

export const SelectComponent = ({type, name, value, label, helperText, changeHandler, icon}) => {
    const classes = useStyles()
    let inner;

    const multiChangeHandler = (event) => {
        const fieldName = event.target.name
        const fieldChecked = event.target.checked
        const newOptions = []
        for (const option of value) {
            if (option.name === fieldName) {
                option.checked = fieldChecked
            }
            newOptions.push(option)
        }
        changeHandler({
            ...event,
            target: {
                ...event.target,
                name: name,
                value: newOptions
            }
        })
    }

    const singleChangeHandler = (event) => {
        const fieldName = event.target.value
        const newOptions = []
        for (const option of value) {
            option.checked = option.name === fieldName;
            newOptions.push(option)
        }
        changeHandler({
            ...event,
            target: {
                ...event.target,
                name: name,
                value: newOptions
            }
        })
    }

    if (type === SELECTION_SINGLE) {
        inner = (
            <>
                <InputLabel className={classes.iconLabel}>{icon} {label}</InputLabel>
                <Select
                    onChange={singleChangeHandler}
                    name={name}
                    value={value?.filter(option => option.checked === true)[0].name}
                    fullWidth>
                    {
                        value?.map((option, idx) => (
                            <MenuItem key={idx} value={option.name}>{option.label}</MenuItem>
                        ))
                    }
                </Select>
            </>
        )
    } else if (type === SELECTION_MULTIPLE) {
        inner = (
            <>
                <FormLabel component={'label'} className={classes.iconLabel}>{icon} {label}</FormLabel>
                <FormGroup row>
                    {
                        value?.map((option, idx) => (
                            <FormControlLabel
                                key={idx}
                                label={option.label}
                                control={<Checkbox name={option.name}
                                                   checked={option.checked}
                                                   onChange={multiChangeHandler}
                                                   color={"primary"}/>}
                            />
                        ))
                    }
                </FormGroup>
            </>
        )
    }
    return (
        <FormControl fullWidth component={'fieldset'}>
            {inner}
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}