import React from "react";
import {useGuardianDetailsService} from "./GuardianDetailsService";
import {useSharedStyles} from "../shared/SharedStyles";
import {Header} from "../shared/header/Header";
import {FormComponent} from "../shared/forms/FormComponent";
import {Spinner} from "../shared/Spinner";

export const GuardianDetails = () => {
    const {
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        fields,
        guardian,
        isGuardianLoading
    } = useGuardianDetailsService()

    const sharedClasses = useSharedStyles()

    if (fields.length === 0)
        return <Spinner/>

    return (
        <React.Fragment>
            <Header title={headerTitle}
                    subtitle={headerSubtitle}/>
            <div className={sharedClasses.pageContent}>
                {
                    isGuardianLoading ? (
                        <Spinner/>
                    ) : (
                        <FormComponent fields={fields}
                                       formButtonText={formButtonText}
                                       onSubmit={onFormSubmit}
                                       initialValues={guardian}/>
                    )
                }
            </div>
        </React.Fragment>
    )
}