import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";
import {UiActions} from "../../store/actions/UiActions";
import {EmployeeActions} from "../../store/actions/EmployeeActions";
import {ResponseStatus} from "../shared/Constants";
import {useHistory} from "react-router-dom";

const tabbedNavigation = {
    0: {
        tabName: "caretakers",
        filterValue: "caretaker"
    },
    1: {
        tabName: "operators",
        filterValue: "operator"
    },
    2: {
        tabName: "field workers",
        filterValue: "field_worker"
    }
}

export const useEmployeeListService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()
    const [denseView, setDenseView] = useState(true)

    const {
        employees,
        areEmployeesLoading,
        employeesFetchStatus,
        employeesFetchMessage
    } = useSelector(state => state.employees)

    const activeTab = useSelector(state => state.ui.activeTab)

    const navigateToEmployeeDetails = (employeeId) => {
        history.push(`/employees/${employeeId}`)
    }

    const navigateToEmployeeCreate = () => {
        history.push('/employees/create')
    }

    const denseViewHandler = () => {
        setDenseView(!denseView)
    }

    const updateTabbedNavigation = useCallback(() => {
        const tabList = Object.values(tabbedNavigation).map(value => value.tabName)
        dispatch(UiActions.updateTabList(tabList))

        // cleanup on component dismount
        return () => {
            dispatch(UiActions.cleanup())
        }
    }, [dispatch])

    const updateTableData = useCallback(() => {
        const filter = tabbedNavigation[activeTab].filterValue
        getAccessTokenSilently().then(token => {
            dispatch(EmployeeActions.fetchEmployees(token, filter))
        })

        // cleanup on component dismount
        return () => {
            dispatch(EmployeeActions.cleanup())
        }
    }, [activeTab, dispatch, getAccessTokenSilently])

    const handleError = useCallback(() => {
        if (employeesFetchStatus === ResponseStatus.ERROR) {
            alert(employeesFetchMessage)
        }
    }, [employeesFetchMessage, employeesFetchStatus])

    useEffect(updateTabbedNavigation, [])
    useEffect(updateTableData, [activeTab])
    useEffect(handleError, [employeesFetchStatus])

    return {
        employees,
        areEmployeesLoading,
        navigateToEmployeeCreate,
        navigateToEmployeeDetails,
        denseViewHandler,
        denseView
    }

}