import {makeStyles} from "@material-ui/core/styles";

export const useLoginLandingStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: 'white',
        marginTop: '7.5vh',
        height: '85vh',
        borderRadius: theme.spacing(2)
    },
    heading: {
        fontWeight: "bold"
    },
    button: {
        padding: theme.spacing(1, 6),
        marginTop: theme.spacing(4)
    },
}))