import React from "react";
import {Header} from "../shared/header/Header";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import {useSharedStyles} from "../shared/SharedStyles";
import {useGuardianListService} from "./GuardianListService";
import {Spinner} from "../shared/Spinner";
import {GuardianTable} from "./GuardianTable";
import {DenseTableComponent} from "../shared/dense_table/DenseTableComponent";

export const GuardianList = () => {
    const {
        guardians,
        areGuardiansLoading,
        navigateToGuardianCreate,
        navigateToGuardianDetails,
        denseViewHandler,
        denseView
    } = useGuardianListService()

    const sharedClasses = useSharedStyles()

    return (
        <React.Fragment>
            <Header title={'Guardian list'}
                    subtitle={'View and manage your guardians'}
                    buttonText={'Create guardian'}
                    buttonIcon={<CreateNewFolderIcon/>}
                    buttonAction={navigateToGuardianCreate}
                    buttonDenseViewAction={denseViewHandler}
                    denseView={denseView}/>
            <div className={sharedClasses.pageContent}>
                {
                    areGuardiansLoading ? (
                        <Spinner/>
                    ) : (
                        denseView ? (
                            <DenseTableComponent
                                headers={["First name", "Last name", "Phone number", "E-mail",
                                    "Work from", "Work to", ""]}
                                rows={guardians}
                                navigateToDetails={(guardianId) => navigateToGuardianDetails(guardianId)}
                                fields={["auth_user.first_name", "auth_user.last_name", "mobile_phone",
                                    "auth_user.email", "work_hours_from", "work_hours_to"]}
                            />
                        ) : (
                            <GuardianTable guardians={guardians}
                                           cardClickHandler={(guardianId) => navigateToGuardianDetails(guardianId)}/>
                        )
                    )
                }
            </div>
        </React.Fragment>

    )
}