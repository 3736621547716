import {ResponseStatus} from "./Constants";

export const successResponse = (response) => {
    // console.log(`SUCCESS: ${JSON.stringify(response, null, 2)}`)

    return {
        data: response.data,
        message: null,
        status: ResponseStatus.SUCCESS
    }
}

export const errorResponse = (reason) => {
    // console.log(`ERROR: ${JSON.stringify(reason, null, 2)}`)

    return {
        data: null,
        message: reason.message,
        status: ResponseStatus.ERROR
    }
}

export const clone = (obj) => {
    if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
        return obj;

    let temp
    if (obj instanceof Date)
        temp = new obj.constructor(); //or new Date(obj);
    else
        temp = obj.constructor();

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null;
            temp[key] = clone(obj[key]);
            delete obj['isActiveClone'];
        }
    }

    return temp;
}
