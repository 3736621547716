import React from "react";
import {MyCalendar} from "./Calendar";
import {makeStyles} from "@material-ui/styles";
import {Header} from "../shared/header/Header";

const useStyle = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
}))

const Events = (props) => {
    const classes = useStyle();

    return (
        <>
            <Header title={'Events'}
                    subtitle={'Easily navigate through your events'}/>
            <MyCalendar/>
        </>
    )
}

export default Events
