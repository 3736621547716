import React from "react";
import {useTableRowStyles} from "../shared/accounts/TableRowStyles";
import {Avatar, Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core";

export const TaskTableRow = ({task, cardClickHandler}) => {
    const classes = useTableRowStyles()
    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => cardClickHandler(task.id)}>
                <CardContent className={classes.content}>
                    <Grid container spacing={4} alignItems={"center"}>
                        <Grid item xs={1}>
                            <Avatar variant={"circle"}>
                                {task.priority[0]}
                            </Avatar>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={"h6"}>
                                {task.title}
                            </Typography>
                            <Typography variant={"body1"}>
                                Due date: {task.due_date}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"}>
                                Assigned by: {task.created_by.first_name} {task.created_by.last_name}
                            </Typography>
                            <Typography variant={"body1"}>
                                Assigned to: {task.assigned_to.first_name} {task.assigned_to.last_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"}>
                                Priority: {task.priority}
                            </Typography>
                            <Typography variant={"body1"}>
                                Resolution type: {task.resolution_type}
                            </Typography>
                            <Typography variant={"body1"}>
                                Status: {task.status}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}