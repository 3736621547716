import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from "./app/App";
import {Provider} from "react-redux";
import {store} from "./store";
import {Auth0Provider} from "@auth0/auth0-react";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import * as Sentry from "@sentry/react";
import {ThemeProvider} from "@material-ui/styles";
import {customTheme} from "./layout/Theme";
import {BrowserRouter} from "react-router-dom";

Sentry.init({dsn: "https://990f74f7260d4896a72aa0963bb3d792@o467353.ingest.sentry.io/5493614"});

ReactDOM.render(
    <React.Fragment>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            redirectUri={process.env.REACT_APP_DEPLOY_PRIME_URL ? process.env.REACT_APP_DEPLOY_PRIME_URL : process.env.REACT_APP_SELF_URL}
        >
            <ThemeProvider theme={customTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Provider store={store}>
                        {/*<Sentry.ErrorBoundary fallback={"An error has occurred"}>*/}
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                        {/*</Sentry.ErrorBoundary>*/}
                    </Provider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Auth0Provider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
