import {useAuth0} from "@auth0/auth0-react";
import {useFormUtils} from "../shared/forms/FormUtils";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TaskActions} from "../../store/actions/TaskActions";
import {useCallback, useEffect, useState} from "react";
import {TaskFormFields} from "../shared/fields/TaskFields";
import {AuthActions} from "../../store/actions/AuthActions";
import {ResponseStatus} from "../shared/Constants";
import {ResidentActions} from "../../store/actions/ResidentActions";

export const useTaskDetailsService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const {fieldsToRequestObject} = useFormUtils()
    const {id} = useParams()
    const dispatch = useDispatch()
    const {
        task,
        isTaskLoading,
        taskCreateStatus,
        taskCreateMessage,
        taskUpdateStatus,
        taskUpdateMessage
    } = useSelector(state => state.tasks)
    const accounts = useSelector(state => state.auth.accounts)

    const headerTitle = id ? 'Task details' : 'Create task'
    const headerSubtitle = id ? 'View and edit task details' : 'Enter task data'
    const formButtonText = id ? 'Save task' : 'Create task'
    const [fields, setFields] = useState([])
    const history = useHistory()

    const onFormSubmit = (formData) => {
        getAccessTokenSilently().then(token => {
            if (id) {
                const request = stateToRequest(formData)
                dispatch(TaskActions.saveTask(token, id, request))
            } else {
                const request = stateToRequest(formData)
                dispatch(TaskActions.createTask(token, request))
            }
        })
    }

    const pushHistory = () => {
        if (taskCreateStatus === ResponseStatus.SUCCESS || taskUpdateStatus === ResponseStatus.SUCCESS){
            history.push("/tasks")
            dispatch(ResidentActions.cleanup())
        } else if (taskCreateStatus === ResponseStatus.ERROR) {
            alert(taskCreateMessage)
        } else if (taskUpdateStatus === ResponseStatus.ERROR) {
            alert(taskUpdateMessage)
        }
    }

    useEffect(pushHistory, [taskCreateStatus, taskCreateMessage, taskUpdateStatus, taskUpdateMessage])

    const stateToRequest = (formData) => {
        const taskRequestObj = fieldsToRequestObject(TaskFormFields, formData)
        return {
            ...taskRequestObj,
        }
    }

    const fetchData = useCallback(() => {
        getAccessTokenSilently().then(token => {
            dispatch(AuthActions.fetchAccounts(token))
        })

        if (id) {
            getAccessTokenSilently().then(token => {
                dispatch(TaskActions.fetchTask(token, id))
            })
        }

        return () => {
            dispatch(TaskActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently, id])

    useEffect(fetchData, [id])

    const setupFields = useCallback(() => {
        if (accounts) {
            const options = accounts.map((account, idx) => (
                {
                    name: account.id,
                    label: `${account.first_name} ${account.last_name}`,
                    checked: idx === 0
                }
            ))

            const newFields = []
            for (const field of TaskFormFields) {
                if (field.name === 'assigned_to' || field.name === 'created_by') {
                    field.options = options
                }
                newFields.push(field)
            }
            setFields(() => newFields)
        }

        return () => {
            AuthActions.cleanup()
        }
    }, [accounts])

    useEffect(setupFields, [accounts])

    return {
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        task,
        fields,
        isTaskLoading
    }
}