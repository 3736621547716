import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {ResidentActions} from "../../store/actions/ResidentActions";
import {ResponseStatus} from "../shared/Constants";

export const useResidentListService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()
    const [denseView, setDenseView] = useState(true)

    const {
        residents,
        areResidentsLoading,
        residentsFetchStatus,
        residentsFetchMessage
    } = useSelector(state => state.residents)

    const navigateToResidentCreate = () => {
        history.push('/residents/create')
    }

    const navigateToResidentDetails = (employeeId) => {
        history.push(`/residents/${employeeId}`)
    }

    const denseViewHandler = () => {
        setDenseView(!denseView)
    }

    const updateTableData = useCallback(() => {
        getAccessTokenSilently().then(token => {
            dispatch(ResidentActions.fetchResidents(token))
        })

        // cleanup on component dismount
        return () => {
            dispatch(ResidentActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently])

    const handleError = useCallback(() => {
        if (residentsFetchStatus === ResponseStatus.ERROR) {
            alert(residentsFetchMessage)
        }
    }, [residentsFetchMessage, residentsFetchStatus])

    useEffect(updateTableData, [])
    useEffect(handleError, [residentsFetchStatus])

    return {
        residents,
        areResidentsLoading,
        navigateToResidentCreate,
        navigateToResidentDetails,
        denseViewHandler,
        denseView
    }
}