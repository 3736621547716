import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {EmployeeActions} from "../../store/actions/EmployeeActions";
import {GuardianActions} from "../../store/actions/GuardianActions";
import {useFormUtils} from "../shared/forms/FormUtils";
import {AuthActions} from "../../store/actions/AuthActions";
import {AddressFields} from "../shared/fields/AddressFields";
import {AuthFormFields} from "../shared/fields/AuthFields";
import {GuardianFormFields} from "../shared/fields/GuardianFields";
import {ResponseStatus} from "../shared/Constants";

export const useGuardianDetailsService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const {fieldsToRequestObject} = useFormUtils()
    const {id} = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const { guardian, isGuardianLoading } = useSelector(state => state.guardians)
    const {
        accountSaveStatus,
        accountSaveMessage,
        accountUpdateStatus,
        accountUpdateMessage
    } = useSelector(state => state.auth)

    const headerTitle = id ? 'Guardian details' : 'Create guardian'
    const headerSubtitle = id ? 'View and edit guardian details' : 'Enter guardian data'
    const formButtonText = id ? 'Save guardian' : 'Create guardian'
    const fields = [...AuthFormFields, ...AddressFields, ...GuardianFormFields]

    const onFormSubmit = (formData) => {
        getAccessTokenSilently().then(token => {
            if (id) {
                const request = stateToRequestSave(formData)
                dispatch(AuthActions.saveAccount(token, id, "guardian", request))
            } else {
                const request = stateToRequest(formData)
                dispatch(AuthActions.registerUser(token, request))
            }
        })
    }

    const pushHistory = () => {
        if (accountSaveStatus === ResponseStatus.SUCCESS || accountUpdateStatus === ResponseStatus.SUCCESS) {
            history.push("/guardians")
        } else if (accountSaveStatus === ResponseStatus.ERROR) {
            alert(accountSaveMessage)
        } else if (accountUpdateStatus === ResponseStatus.ERROR) {
            alert(accountUpdateMessage)
        }
        return () => {
            dispatch(AuthActions.cleanup())
        }
    }

    useEffect(pushHistory, [accountSaveStatus, accountSaveMessage, accountUpdateStatus, accountUpdateMessage])

    const stateToRequestSave = (formData) => {
        const authRequestObj = fieldsToRequestObject(AuthFormFields, formData)
        const addressRequestObj = fieldsToRequestObject(AddressFields, formData)
        const guardianRequestObj = fieldsToRequestObject(GuardianFormFields, formData)
        return {
            auth_user: {
                ...authRequestObj,
                groups: []
            },
            positions: ['guardian'],
            type: 'guardian',
            ...addressRequestObj,
            ...guardianRequestObj
        }
    }

    const stateToRequest = (formData) => {
        const authRequestObj = fieldsToRequestObject(AuthFormFields, formData)
        const addressRequestObj = fieldsToRequestObject(AddressFields, formData)
        const guardianRequestObj = fieldsToRequestObject(GuardianFormFields, formData)
        return {
            ...authRequestObj,
            positions: ['guardian'],
            type: 'guardian',
            guardian: {
                ...addressRequestObj,
                ...guardianRequestObj
            }
        }
    }

    useEffect(() => {
        if (id) {
            getAccessTokenSilently().then(token => {
                dispatch(GuardianActions.fetchGuardian(token, id))
            })
        }

        return () => {dispatch(EmployeeActions.cleanup())}
    }, [dispatch, getAccessTokenSilently, id])

    return {
        id,
        headerTitle,
        headerSubtitle,
        formButtonText,
        onFormSubmit,
        fields,
        guardian,
        isGuardianLoading
    }
}