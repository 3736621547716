import React from 'react';
import {Layout} from "../layout/Layout";
import {useAuth0} from "@auth0/auth0-react";
import {routes} from "../configs/routes";
import GuardedRoute from "../components/auth/GuardedRoute";
import {Route, Switch} from "react-router-dom";
import './App.css';

const App = () => {
    const {isAuthenticated} = useAuth0()
    return (
        <Layout>
            <Switch>
                {
                    routes.map(route => {
                        return route.protected ? (
                            <GuardedRoute exact
                                          key={route.path}
                                          path={route.path}
                                          component={route.component}
                                          isAuthenticated={isAuthenticated}/>
                        ) : (
                            <Route exact
                                   key={route.path}
                                   path={route.path}
                                   component={route.component}/>
                        )
                    })
                }
            </Switch>
        </Layout>
    );
}

export default App;
