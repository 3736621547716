import {AUTH_REGISTER_USER, AUTH_SAVE_USER, CLEANUP_AUTH, FETCH_ACCOUNTS} from "../actions/action.types";

const initialState = {
    accounts: null,
    accountSaveStatus: null,
    accountSaveMessage: null,
    accountUpdateStatus: null,
    accountUpdateMessage: null
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_REGISTER_USER:
            return {
                ...state,
                accountSaveStatus: action.payload.status,
                accountSaveMessage: action.payload.message
            }
        case AUTH_SAVE_USER:
            return {
                ...state,
                accountUpdateStatus: action.payload.status,
                accountUpdateMessage: action.payload.message
            }
        case FETCH_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload.data
            }
        case CLEANUP_AUTH:
            return {
                initialState
            }
        default:
            return {
                ...state
            }
    }
}
