import React from "react";
import {useTableStyles} from "../shared/accounts/TableStyles";
import {TaskTableRow} from "./TaskTableRow";

export const TaskTable = ({tasks, cardClickHandler}) => {
    const classes = useTableStyles()
    return (
        <div className={classes.root}>
            {
                tasks.map(task => (
                    <div className={classes.item}
                         key={task.id}>
                        <TaskTableRow task={task}
                                      cardClickHandler={cardClickHandler}/>
                    </div>
                ))
            }
        </div>
    )
}