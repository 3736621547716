import {useDispatch, useSelector} from "react-redux";
import {UiActions} from "../store/actions/UiActions";

export const useLayoutService = () => {

    const dispatch = useDispatch()
    const activeTab = useSelector(state => state.ui.activeTab)
    const tabNamesList = useSelector(state => state.ui.tabNamesList)

    const showTabs = tabNamesList?.length > 0
    const handleTabChange = (event, idx) => {
        dispatch(UiActions.updateActiveTab(idx))
    }

    return {
        showTabs,
        activeTab,
        handleTabChange,
        tabNamesList
    }
}