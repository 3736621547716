import React, {useEffect, useState} from "react";
import {FormComponent} from "../forms/FormComponent";
import {useDispatch, useSelector} from "react-redux";
import {ResidentActions} from "../../../store/actions/ResidentActions";
import {useFormUtils} from "../forms/FormUtils";


export const useStepperFormComponentService = ({
                                                   firstPartFormFields,
                                                   secondPartFormFields,
                                                   thirdPartFormFields,
                                                   fourthPartFormFields,
                                                   onSubmit,
                                                   initialValues,
                                                   scroll,
                                                   activeStep,
                                                   setActiveStep
                                               }) => {
    const dispatch = useDispatch()
    const formUtils = useFormUtils()
    const {residentRequest, isRequestUpdating} = useSelector(state => state.residents)
    const fields = [firstPartFormFields, secondPartFormFields, thirdPartFormFields, fourthPartFormFields]
    const [flagForInitialValues, setFlagForInitialValues] = useState(true)

    const getCurrentInitValues = (currentFields) => {
        const formObject = {}
        for (const field of currentFields) {
            if (field.isSelection) {
                if (residentRequest && residentRequest[field])
                    formObject[field.name] = residentRequest[field].value
                else
                    formObject[field.name] = field.options[0].name
            } else {
                formObject[field.name] = formUtils.getValueToSet(field, residentRequest)
            }
        }
        return formObject
    }

    function getStepContent(step) {
        let submitHandler = null
        let formButtonText = "Next"
        let secondFormButtonText = "Back"
        let secondButtonOnSubmit = handleBack
        switch (step) {
            case 0:
                submitHandler = handleNext
                secondButtonOnSubmit = null
                secondFormButtonText = null
                break;
            case 1:
                submitHandler = handleNext
                break;
            case 2:
                submitHandler = handleNext
                break;
            case 3:
                submitHandler = handleSubmit
                formButtonText = "Submit"
                break;
        }
        const currentStepFields = fields[step]
        const currentInitialValues = getCurrentInitValues(currentStepFields)
        return <FormComponent key={step}
                              fields={currentStepFields}
                              initialValues={currentInitialValues}
                              secondButtonOnSubmit={secondButtonOnSubmit}
                              secondFormButtonText={secondFormButtonText}
                              onSubmit={submitHandler}
                              formButtonText={formButtonText}/>
    }

    const handleNext = (event) => {
        dispatch(ResidentActions.updateResidentRequest(event, true))
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        scroll()
    };

    const handleBack = (event) => {
        dispatch(ResidentActions.updateResidentRequest(event, true))
        setActiveStep(prevActiveStep => prevActiveStep - 1)
        scroll()
    };

    const handleSubmit = (event) => {
        dispatch(ResidentActions.updateResidentRequest(event, false))
    }

    const onSubmitWrapper = () => {
        if (!isRequestUpdating)
            onSubmit(residentRequest)
    }

    const initForm = () => {
        const formObject = {}
        let data
        if (flagForInitialValues && initialValues){
            setFlagForInitialValues(false)
            data = initialValues
        }else {
            data = residentRequest
        }
        const innerFields = [...firstPartFormFields, ...secondPartFormFields, ...thirdPartFormFields, ...fourthPartFormFields]
        for (const field of innerFields) {
            if (field.isSelection) {
                formObject[field.name] = JSON.parse(JSON.stringify(field.options))
                if (data && data[field.name]) {
                    formObject[field.name].value = data[field.name]
                }
            } else {
                formObject[field.name] = formUtils.getValueToSet(field, data)
            }
        }
        dispatch(ResidentActions.updateResidentRequest(formObject, true))
    }

    useEffect(initForm, [activeStep])

    /*
    * Because of using thunks in the reducer 'isRequestUpdating' is being updated async.
    * Due to this we need to monitor the variable outside of the function for handling the submit.
    */
    useEffect(onSubmitWrapper, [isRequestUpdating])

    return {
        getStepContent
    }
}