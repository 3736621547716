export const EmployeePositionType = {
    operator: "Operator",
    field_worker: "Field worker",
    caretaker: "Caretaker",
    administrator: "Administrator",
    operator_lead: "Operator lead",
    field_worker_lead: "Field worker lead",
    caretaker_lead: "Caretaker lead"
}

export const EmployeeType = {
    part_time: "Part-time",
    salaried: "Salaried",
    contract: "Contract"
}

export const AgeGroup = {
    minor: "0-17",
    young_adult: "18-35",
    older_adult: "36-59",
    elder: "60-"
}

export const DefaultResident = {
    "full_name": "",
    "guardian": "",
    "medical_report": "",
    "social_no": "",
    "address": "",
    "years_at_address": 0,
    "gender": "Male",
    "birth_date": null,
    "birth_place": "",
    "telephone_number": "",
    "current_living": "Own home",
    "own_car": false,
    "drive_regularly": false,
    "maintain_car": false,
    "living_concerns": "",
    "require_living_assistant": false,
    "assistant": "",
    "reason_assistant": "",
    "day_visit": false,
    "reason_day_visit": "",
    "length_day_visit": 0,
    "physician": "",
    "physician_address": "",
    "physician_telephone": "",
    "hospital": "",
    "describe_state_of_health": "",
    "often_doctor_visit": "",
    "last_doctor_visit": null,
    "walking": "High",
    "walking_instrument": "",
    "if_medications": false,
    "medications": "",
    "medications_assistance": false,
    "own_meals": false,
    "if_diet": false,
    "diet": "",
    "health_insurance": false,
    "medicare_insurance": false,
    "insurances": "",
    "ad8_score": 0,
    "handle_bathing": false,
    "handle_dressing": false,
    "handle_mouth_skin_care": false,
    "handle_grooming": false,
    "handle_toileting": false,
    "handle_mobility": false,
    "handle_med_reminder": false,
    "handle_night_care": false,
    "handle_housekeeping": false,
    "handle_clothing": false,
    "place_born": "",
    "place_childhood": "",
    "place_young_adult": "",
    "place_older_adult": "",
    "education": "",
    "employment": "",
    "other_interests": "",
    "vacation_travel_spots": "",
    "religion": "",
    "military": "",
    "personal_characteristics": "",
    "other_information": "",
    "listening_music": "",
    "singing": "",
    "playing_instruments": "",
    "dancing": "",
    "enjoy_pets": "",
    "sports": "",
    "exercising": "",
    "reading": "",
    "card_games": "",
    "board_games": "",
    "drawing": "",
    "knitting": "",
    "sightseeing": "",
    "other_activities": ""
}

export const DefaultDoctor = {
    "first_name": "",
    "last_name": "",
    "specialization": "",
    "main_hospital": "",
    "mobile_phone": "",
    "work_phone": "",
    "email": ""
}

export const DefaultDiagnosis = {
    "doctor": "",
    "history": "",
    "self_diagnosis": "",
    "provisional_diagnosis": "",
    "differential_diagnoses": "",
    "symptoms": "",
    "judgment": "",
    "plan": ""
}

export const DefaultMedialReport = {
    "doctor": "",
    "diagnosis": "",
    "family_doctor": "",
    "regimen": "",
    "immunization": "",
    "allergies": "",
    "hospitalizations": "",
    "physician_notes": "",
    "weight": 0,
    "height": 0,
    "heent": "",
    "respiratory": "",
    "cardiac": "",
    "vascular": "",
    "gastro_intestinal": "",
    "genitourinary": "",
    "neuromuscular": "",
    "emotional": "",
    "hematological": "",
    "rheumatic": "",
    "endocrine": "",
    "dermatological": "",
    "dietary_restrictions": "",
    "occupation": "",
    "habits": "",
    "present_environment": "",
    "financial": "",
    "psychosocial": "",
    "general": "",
    "illnesses": ""
}

export const ResponseStatus = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
}