import {Container, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useLoginLandingStyles} from "./LoginLandingStyles";

export const LoginLanding = () => {
    const classes = useLoginLandingStyles()
    const {loginWithRedirect} = useAuth0()

    return (
        <Container maxWidth={'lg'} className={classes.root}>
            <Typography variant={"h2"} color={'primary'} className={classes.heading}>
                Welcome to Carrie
            </Typography>
            <Button
                size={'large'}
                variant={'contained'}
                color={'primary'}
                className={classes.button}
                onClick={() => loginWithRedirect()}
                startIcon={<LockOpenIcon/>}>
                Login
            </Button>
        </Container>
    )
}