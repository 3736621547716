import React from "react";
import {useSharedStyles} from "../shared/SharedStyles";
import {Header} from "../shared/header/Header";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import {useResidentListService} from "./ResidentListService";
import {Spinner} from "../shared/Spinner";
import {ResidentTable} from "./ResidentTable";
import {DenseTableComponent} from "../shared/dense_table/DenseTableComponent";

export const ResidentList = () => {
    const {
        residents,
        areResidentsLoading,
        navigateToResidentCreate,
        navigateToResidentDetails,
        denseViewHandler,
        denseView
    } = useResidentListService()

    const sharedClasses = useSharedStyles()

    return (
        <React.Fragment>
            <Header title={'Resident List'}
                    subtitle={'View and manage your residents'}
                    buttonText={'Create resident'}
                    buttonIcon={<CreateNewFolderIcon/>}
                    buttonAction={navigateToResidentCreate}
                    buttonDenseViewAction={denseViewHandler}
                    denseView={denseView}/>
            <div className={sharedClasses.pageContent}>
                {
                    areResidentsLoading ? (
                        <Spinner/>
                    ) : (
                        denseView ? (
                            <DenseTableComponent
                                headers={["Full name", "Physician", "Last doctor visit", ""]}
                                rows={residents}
                                navigateToDetails={(residentId) => navigateToResidentDetails(residentId)}
                                fields={["full_name", "physician", "last_doctor_visit"]}
                            />
                        ) : (
                            <ResidentTable residents={residents}
                                           cardClickHandler={(residentId) => navigateToResidentDetails(residentId)}/>
                        )
                    )
                }
            </div>
        </React.Fragment>
    )
}