import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {Link, useLocation, useParams} from "react-router-dom";
import routeMap from "../../../configs/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    breadcrumb: {
        fontWeight: "light",
        fontSize: '0.85rem',
        color: theme.palette.grey["400"]
    }
}));

export const HeaderBreadcrumbs = () => {
    const classes = useStyles();
    const location = useLocation()
    const params = useParams()
    const original = location.pathname.split('/').filter((x) => x)
    const reconstructed = []
    for (const path of original) {
        const k = Object.keys(params).find(key => params[key] === path);
        if (k) {
            reconstructed.push(`:${k}`)
        } else {
            reconstructed.push(path)
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs separator="›" color={'inherit'} aria-label="breadcrumb">
                {
                    reconstructed.map((path, index) => {
                        const last = index === reconstructed.length - 1;
                        const key = `/${reconstructed.slice(0, index + 1).join('/')}`;
                        const to = `/${original.slice(0, index + 1).join('/')}`;
                        const val = routeMap[key]

                        if (!val) return null

                        return last ? (
                            <Typography key={to} className={classes.breadcrumb}>
                                {val}
                            </Typography>
                        ) : (
                            <Link to={to} key={to} className={classes.breadcrumb}>
                                {val}
                            </Link>
                        )
                    })
                }
            </Breadcrumbs>
        </div>
    );
}
