import React from "react";
import {EmployeeTableRow} from "./EmployeeTableRow";
import {useTableStyles} from "../shared/accounts/TableStyles";

export const EmployeeTable = ({employees, cardClickHandler}) => {
    const classes = useTableStyles()

    return (
        <div className={classes.root}>
            {
                employees?.map(employee => (
                    <div className={classes.item}
                         key={employee.auth_user.username}>
                        <EmployeeTableRow employee={employee}
                                          cardClickHandler={cardClickHandler}/>
                    </div>
                ))
            }
        </div>
    )
}