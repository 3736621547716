import React from "react";
import {useTasksListService} from "./TasksListService";
import {Header} from "../shared/header/Header";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import {useSharedStyles} from "../shared/SharedStyles";
import {Spinner} from "../shared/Spinner";
import {DenseTableComponent} from "../shared/dense_table/DenseTableComponent";
import {TaskTable} from "./TaskTable";

export const TasksList = () => {

    const {
        tasks,
        areTasksLoading,
        navigateToTaskCreate,
        navigateToTaskDetails,
        denseViewHandler,
        denseView
    } = useTasksListService()

    const sharedClasses = useSharedStyles()

    return (
        <React.Fragment>
            <Header title={'Task List'}
                    subtitle={'View and manage your tasks'}
                    buttonText={'Create task'}
                    buttonAction={navigateToTaskCreate}
                    buttonIcon={<CreateNewFolderIcon/>}
                    buttonDenseViewAction={denseViewHandler}
                    denseView={denseView}/>
            <div className={sharedClasses.pageContent}>
                {
                    areTasksLoading ? (
                        <Spinner/>
                    ) : (
                        denseView ? (
                            <DenseTableComponent
                                headers={["Title", "Status", "Priority", "Due date", ""]}
                                rows={tasks}
                                navigateToDetails={(taskId) => navigateToTaskDetails(taskId)}
                                fields={["title", "status", "priority", "due_date"]}/>
                        ) : (
                            <TaskTable tasks={tasks}
                                       cardClickHandler={(taskId) => navigateToTaskDetails(taskId)}/>
                        )
                    )
                }
            </div>
        </React.Fragment>
    )
}
