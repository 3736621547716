import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {getConfig} from "../../../configs/config_loader";
import {ResidentActions} from "../../../store/actions/ResidentActions";
import {MedicationActions} from "../../../store/actions/MedicationActions";
import {useDispatch, useSelector} from "react-redux";
import {MedicalReportFields} from "../../shared/fields/MedicalReportFields";
import {useFormUtils} from "../../shared/forms/FormUtils";

export const useMedicalReportService = () => {
    const params = useParams()
    const report_id = params.id
    const [canEdit, setEditable] = useState(!report_id || false)
    const [config, setConfig] = useState(null)
    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()
    const {fieldsToRequestObject} = useFormUtils()
    const {doctors, diagnosis} = useSelector(state => state.medications)
    const medical_report = useSelector(state => state.residents.medical_report_details)
    const [fields, setFields] = useState([...MedicalReportFields])

    const fetchData = () => {
        getAccessTokenSilently().then(token => {
            dispatch(MedicationActions.fetchDoctors(token))
            dispatch(MedicationActions.fetchAllDiagnosis(token))
            report_id ? dispatch(ResidentActions.fetchMedicalReport(token, report_id)) :
                dispatch(ResidentActions.clearReportDetails())
        })
        getConfig().then(setConfig)
    }

    useEffect(fetchData, [])


    const handleChange = (event) => {
        if (!canEdit) return;
        const {name, value} = event.target
        dispatch(ResidentActions.updateReportDetails(name, value))
    }

    const handleCreate = () => {
        getAccessTokenSilently().then(token => {
            dispatch(ResidentActions.createReport(token, medical_report))
        })
    }

    const handleSave = (formData) => {
        getAccessTokenSilently().then(token => {
            const request = stateToRequestSave(formData)
            console.log(request)
            dispatch(ResidentActions.saveReport(token, report_id, request))
        })
    }

    const handleDoctorClick = (id) => {
        if (id)
            history.push(`/doctors/details/${id}`)
    }

    const handleDiagnosisClick = () => {
        if (medical_report.diagnosis)
            history.push(`/diagnosis/details/${medical_report.diagnosis}`)
    }

    const setupDoctorFields = () => {
        if (doctors) {
            const options = doctors.map((doctor, idx) => ({
                name: doctor.id,
                label: `${doctor.first_name} ${doctor.last_name}`,
                checked: idx === 0
            }))

            const newFields = []
            for (const field of MedicalReportFields) {
                if (field.name === 'doctor' || field.name === 'family_doctor'){
                    field.options = options
                }
                newFields.push(field)
            }
            setFields(newFields)
        }
    }

    const setupDiagnosisFields = () => {
        if (diagnosis) {
            const options = diagnosis.map((diagnosis, idx) => ({
                name: diagnosis.id,
                label: `${diagnosis.id}`,
                checked: idx === 0
            }))

            const newFields = []
            for (const field of MedicalReportFields) {
                if (field.name === 'diagnosis'){
                    field.options = options
                }
                newFields.push(field)
            }
            setFields(newFields)
        }
    }

    useEffect(setupDoctorFields, [doctors])
    useEffect(setupDiagnosisFields, [diagnosis])

    const stateToRequestSave = (formData) => {
        const medicalRequestObj = fieldsToRequestObject(MedicalReportFields, formData)
        return {
            ...medicalRequestObj
        }
    }

    return {
        handleChange,
        handleCreate,
        handleSave,
        handleDoctorClick,
        handleDiagnosisClick,
        config,
        report_id,
        setEditable,
        canEdit,
        history,
        doctors,
        diagnosis,
        medical_report,
        fields
    }
}