import React from "react";
import {useTableStyles} from "../shared/accounts/TableStyles";
import {ResidentTableRow} from "./ResidentTableRow";

export const ResidentTable = ({residents, cardClickHandler}) => {
    const classes = useTableStyles()

    return (
        <div className={classes.root}>
            {
                residents?.map(resident => (
                    <div className={classes.item}
                         key={resident.id}>
                        <ResidentTableRow resident={resident}
                                          cardClickHandler={cardClickHandler}/>
                    </div>
                ))
            }
        </div>
    )
}