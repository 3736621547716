import React from "react";

export const useDenseTableComponentService = (() => {

    const getPropsFromNestedObject = (obj, propString) => {
        if (!propString)
            return obj;

        let prop, props = propString.split('.');
        let i = 0;
        let iLen = props.length - 1;

        for (; i < iLen; i++) {
            prop = props[i];

            const candidate = obj[prop];
            if (candidate !== undefined) {
                obj = candidate;
            } else {
                break;
            }
        }
        return obj[props[i]];
    }

    return {
        getPropsFromNestedObject
    }
})