import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {TaskActions} from "../../store/actions/TaskActions";

export const useTasksListService = () => {
    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()
    const [denseView, setDenseView] = useState(true)

    const {
        tasks,
        areTasksLoading
    } = useSelector(state => state.tasks)

    const navigateToTaskDetails = (taskId) => {
        history.push(`/tasks/${taskId}`)
    }

    const navigateToTaskCreate = () => {
        history.push('/tasks/create')
    }

    const denseViewHandler = () => {
        setDenseView(!denseView)
    }

    const updateTableData = useCallback(() => {
        getAccessTokenSilently().then(token => {
            dispatch(TaskActions.fetchExpandedTasks(token))
        })
        return () => {
            dispatch(TaskActions.cleanup())
        }
    }, [dispatch, getAccessTokenSilently])

    useEffect(updateTableData, [])

    return {
        tasks,
        areTasksLoading,
        navigateToTaskCreate,
        navigateToTaskDetails,
        denseViewHandler,
        denseView
    }
}