import React from "react";
import {useEmployeeListService} from "./EmployeeListService";
import {Header} from "../shared/header/Header";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import {Spinner} from "../shared/Spinner";
import {EmployeeTable} from "./EmployeeTable";
import {useSharedStyles} from "../shared/SharedStyles";
import {DenseTableComponent} from "../shared/dense_table/DenseTableComponent";

export const EmployeeList = () => {
    const {
        employees,
        areEmployeesLoading,
        navigateToEmployeeCreate,
        navigateToEmployeeDetails,
        denseViewHandler,
        denseView
    } = useEmployeeListService()

    const sharedClasses = useSharedStyles()

    return (
        <React.Fragment>
            <Header title={'Employee List'}
                    subtitle={'View and manage your employees'}
                    buttonText={'Create employee'}
                    buttonAction={navigateToEmployeeCreate}
                    buttonIcon={<CreateNewFolderIcon/>}
                    buttonDenseViewAction={denseViewHandler}
                    denseView={denseView}/>
            <div className={sharedClasses.pageContent}>
                {
                    areEmployeesLoading ? (
                        <Spinner/>
                    ) : (
                        denseView ? (
                            <DenseTableComponent
                                headers={["First name", "Last name", "Phone number", "Work hours", ""]}
                                rows={employees}
                                navigateToDetails={(employeeId) => navigateToEmployeeDetails(employeeId)}
                                fields={["auth_user.first_name", "auth_user.last_name", "mobile_phone", "work_hours"]}
                            />
                        ) : (
                            <EmployeeTable employees={employees}
                                           cardClickHandler={(employeeId) => navigateToEmployeeDetails(employeeId)}/>
                        )
                    )
                }
            </div>
        </React.Fragment>
    )
}


