import {
    INPUT_BOOLEAN,
    INPUT_DATE,
    INPUT_DATETIME,
    INPUT_TIME,
    SELECTION_MULTIPLE,
    SELECTION_SINGLE
} from "../fields/FieldTypes";
import moment from "moment";

export const useFormUtils = () => {

    const optionsToArray = (options) => {
        return options.filter(option => option.checked).map(option => option.name)
    }

    const optionsToValue = (options) => {
        return options.filter(option => option.checked)[0].name
    }

    const fieldsToRequestObject = (fields, data) => {
        const requestObj = {}
        fields.forEach(field => {
            if (field.type === SELECTION_SINGLE) {
                requestObj[field.name] = optionsToValue(data[field.name])
            } else if (field.type === SELECTION_MULTIPLE) {
                requestObj[field.name] = optionsToArray(data[field.name])
            } else if (field.type === INPUT_DATETIME) {
                requestObj[field.name] = moment(data[field.name])
            } else {
                requestObj[field.name] = data[field.name]
            }
        })
        return requestObj
    }

    const getValueToSet = (field, data) => {
        let valueToSet = field.type === INPUT_BOOLEAN ? false : ""
        valueToSet = field.type === INPUT_DATE ? moment().format("YYYY-MM-DD") : valueToSet
        valueToSet = field.type === INPUT_TIME ? moment().format("hh:mm") : valueToSet
        valueToSet = field.type === INPUT_DATETIME ? moment().format("YYYY-MM-DD HH:mm:ss") : valueToSet
        if (data) {
            valueToSet = data[field.name] ?? valueToSet
        }
        return valueToSet
    }

    return {
        fieldsToRequestObject,
        getValueToSet
    }
}