import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {eventsReducer} from "./reducers/reducer.events";
import {ResidentReducer} from "./reducers/ResidentReducer";
import {UiReducer} from "./reducers/UiReducer";
import {EmployeeReducer} from "./reducers/EmployeeReducer";
import {GuardianReducer} from "./reducers/GuardianReducer";
import {AuthReducer} from "./reducers/AuthReducer"
import {MedicationReducer} from "./reducers/MedicationReducer";
import {TaskReducer} from "./reducers/TaskReducer";

const rootReducer = combineReducers({
    events: eventsReducer,

    residents: ResidentReducer,
    employees: EmployeeReducer,
    guardians: GuardianReducer,
    auth: AuthReducer,
    ui: UiReducer,
    medications: MedicationReducer,
    tasks: TaskReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

