import Dashboard from "../components/dashboard/Dashboard";
import Medications from "../components/medications/Medications";
import Profile from "../components/auth/Profile";
import DoctorForm from "../components/medications/DoctorForm";
import {TasksList} from "../components/tasks/TasksList";
import {Statistics} from "../components/statistics/Statistics";
import Events from "../components/events/Events";
import {MedicalReport} from "../components/residents/medical_report/MedicalReport";
import DiagnosisForm from "../components/medications/DiagnosisForm";
import {EmployeeList} from "../components/employees/EmployeeList";
import {GuardianList} from "../components/guardians/GuardianList";
import {EmployeeDetails} from "../components/employees/EmployeeDetails";
import {GuardianDetails} from "../components/guardians/GuardianDetails";
import {ResidentList} from "../components/residents/ResidentList";
import {ResidentDetails} from "../components/residents/ResidentDetails";
import {TaskDetails} from "../components/tasks/TaskDetails";

export const routes = [
    {
        path: '/',
        name: 'Dashboard',
        protected: false,
        component: Dashboard,
    },
    {
        path: '/profile',
        name: 'Profile',
        protected: true,
        component: Profile,
    },
    {
        path: '/medications',
        name: 'Medications',
        protected: true,
        component: Medications,
    },
    {
        path: '/doctors/create',
        name: 'Create',
        protected: true,
        component: DoctorForm,
    },
    {
        path: '/doctors/:id',
        name: 'Details',
        protected: true,
        component: DoctorForm,
    },
    {
        path: '/tasks',
        name: 'Tasks',
        protected: true,
        component: TasksList,
    },
    {
        path: '/tasks/create',
        name: 'Details',
        protected: true,
        component: TaskDetails,
    },
    {
        path: '/tasks/:id',
        name: 'Create',
        protected: true,
        component: TaskDetails,
    },
    {
        path: '/stats',
        name: 'Statistics',
        protected: true,
        component: Statistics,
    },
    {
        path: '/events',
        name: 'Events',
        protected: true,
        component: Events,
    },
    {
        path: '/residents',
        name: 'Residents',
        protected: true,
        component: ResidentList,
    },
    {
        path: '/residents/create/',
        name: 'Create',
        protected: true,
        component: ResidentDetails,
    },
    {
        path: '/residents/:id',
        name: 'Details',
        protected: true,
        component: ResidentDetails,
    },
    {
        path: '/reports/create',
        name: 'Create',
        protected: true,
        component: MedicalReport,
    },
    {
        path: '/reports/:id',
        name: 'Details',
        protected: true,
        component: MedicalReport,
    },
    {
        path: '/diagnosis/create',
        name: 'Create',
        protected: true,
        component: DiagnosisForm,
    },
    {
        path: '/diagnosis/:id',
        name: 'Details',
        protected: true,
        component: DiagnosisForm,
    },
    {
        path: '/employees',
        name: 'Employees',
        protected: true,
        component: EmployeeList
    },
    {
        path: '/employees/create',
        name: 'Create',
        protected: true,
        component: EmployeeDetails
    },
    {
        path: '/employees/:id',
        name: 'Details',
        protected: true,
        component: EmployeeDetails
    },
    {
        path: '/guardians',
        name: 'Guardians',
        protected: true,
        component: GuardianList
    },
    {
        path: '/guardians/create',
        name: 'Create',
        protected: true,
        component: GuardianDetails
    },
    {
        path: '/guardians/:id',
        name: 'Details',
        protected: true,
        component: GuardianDetails
    }
]

const routeNameToBreadcrumbsMap = {}

for (const route of routes) {
    routeNameToBreadcrumbsMap[route.path] = route.name
}

export default routeNameToBreadcrumbsMap