import {
    CLEANUP_GUARDIANS,
    FETCH_GUARDIAN,
    FETCH_GUARDIANS,
    GUARDIAN_LOADING,
    GUARDIANS_LOADING
} from "../actions/action.types";

const initialState = {
    areGuardiansLoading: false,
    isGuardianLoading: false,
    guardians: null,
    guardiansFetchStatus: null,
    guardiansFetchMessage: null,
    guardian: null,
    guardianFetchStatus: null,
    guardianFetchMessage: null,
}

export const GuardianReducer = (state = initialState, action) => {
    switch (action.type) {
        case GUARDIANS_LOADING: {
            return {
                ...state,
                areGuardiansLoading: true
            }
        }
        case FETCH_GUARDIANS: {
            return {
                ...state,
                areGuardiansLoading: false,
                guardians: action.payload.data,
                guardiansFetchStatus: action.payload.status,
                guardiansFetchMessage: action.payload.message
            }
        }
        case GUARDIAN_LOADING: {
            return {
                ...state,
                isGuardianLoading: true
            }
        }
        case FETCH_GUARDIAN: {
            return {
                ...state,
                isGuardianLoading: false,
                guardian: action.payload.data,
                guardianFetchStatus: action.payload.status,
                guardianFetchMessage: action.payload.message
            }
        }
        case CLEANUP_GUARDIANS: {
            return initialState
        }
        default: {
            return state
        }
    }
}