import {UI_CLEANUP_TAB_LIST, UI_UPDATE_ACTIVE_TAB, UI_UPDATE_TAB_LIST} from "./action.types";

export const UiActions = {
    updateActiveTab: (idx) => dispatch => {
        dispatch({
            type: UI_UPDATE_ACTIVE_TAB,
            payload: idx
        })
    },

    updateTabList: (tabList) => dispatch => {
        dispatch({
            type: UI_UPDATE_TAB_LIST,
            payload: tabList
        })
    },

    cleanup: () => dispatch => {
        dispatch({
            type: UI_CLEANUP_TAB_LIST,
        })
    }
}