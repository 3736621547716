import React from "react";
import {Avatar, Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailIcon from "@material-ui/icons/Mail";
import CreateIcon from "@material-ui/icons/Create";
import {useTableRowStyles} from "../shared/accounts/TableRowStyles";
import {EmployeePositionType, EmployeeType} from "../shared/Constants";
import StoreIcon from "@material-ui/icons/Store";

const displayLeadPositions = (positions) => {
    const positionStrings = positions
        .filter(position => position.indexOf('lead') !== -1)
        .map(position => (
            EmployeePositionType[position]
        ))
    return positionStrings.join(" & ")
}

export const EmployeeTableRow = ({employee, cardClickHandler}) => {
    const classes = useTableRowStyles()
    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => cardClickHandler(employee.id)}>
                <CardContent className={classes.content}>
                    <Grid container spacing={4} alignItems={"center"}>
                        <Grid item xs={1}>
                            <Avatar variant={"circle"}>
                                {employee.auth_user.first_name[0]}
                            </Avatar>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant={"h6"}>{employee.auth_user.first_name} {employee.auth_user.last_name}
                            </Typography>
                            <Typography variant={"body1"}>
                                {displayLeadPositions(employee.positions)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"} className={classes.dual}>
                                <CreateIcon/>
                                <span>{EmployeeType[employee.employee_type]}</span>
                            </Typography>
                            {
                                employee.department && (
                                    <Typography variant={"body1"} className={classes.dual}>
                                        <StoreIcon/>
                                        <span>
                                            {employee.department}
                                        </span>
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"body1"} className={classes.dual}>
                                <PhoneIphoneIcon/>
                                <span>{employee.mobile_phone}</span>
                            </Typography>
                            <Typography variant={"body1"} className={classes.dual}>
                                <MailIcon/>
                                <span>{employee.auth_user.email}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}