import React from "react";
import {useTableStyles} from "../shared/accounts/TableStyles";
import {GuardianTableRow} from "./GuardianTableRow";

export const GuardianTable = ({guardians, cardClickHandler}) => {
    const classes = useTableStyles()

    return (
        <div className={classes.root}>
            {
                guardians?.map(guardian => (
                    <div className={classes.item}
                         key={guardian.auth_user.username}>
                        <GuardianTableRow guardian={guardian}
                                          cardClickHandler={cardClickHandler}/>
                    </div>
                ))
            }
        </div>
    )
}