// ACCOUNTS
export const ACCOUNTS_CLEAR_ACCOUNT_DETAILS = "ACCOUNTS_CLEAR_ACCOUNT_DETAILS"
export const ACCOUNTS_FETCH_USER = "ACCOUNTS_FETCH_USER"
export const ACCOUNTS_FETCH_USERS = "ACCOUNTS_FETCH_USERS"
export const ACCOUNTS_UPDATE_GUARDIAN_DETAILS = "ACCOUNTS_UPDATE_GUARDIAN_DETAILS"

// -- GUARDIAN
export const FETCH_GUARDIANS = 'FETCH_GUARDIANS'
export const FETCH_GUARDIAN = 'FETCH_GUARDIAN'
export const GUARDIANS_LOADING = 'GUARDIANS_LOADING'
export const GUARDIAN_LOADING = 'GUARDIAN_LOADING'
export const CLEANUP_GUARDIANS = 'CLEANUP_GUARDIANS'

// -- EMPLOYEE
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES'
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE'
export const EMPLOYEES_LOADING = 'EMPLOYEES_LOADING'
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING'
export const CLEANUP_EMPLOYEES = 'CLEANUP_EMPLOYEES'

export const ACCOUNTS_UPDATE_EMPLOYEE_DETAILS = 'ACCOUNTS_UPDATE_EMPLOYEE_DETAILS'

// EVENTS
// -- FIELD WORKER VISIT EVENT
export const EVENTS_FETCH_FIELD_WORKER_VISITS = 'EVENTS_FETCH_FIELD_WORKER_VISITS'
export const EVENTS_FETCH_FIELD_WORKER_VISIT = 'EVENTS_FETCH_FIELD_WORKER_VISIT'

// -- VISIT EVENT
export const EVENTS_FETCH_VISITS = 'EVENTS_VISITS'
export const EVENTS_FETCH_VISIT = 'EVENTS_VISIT'

// -- EVENT
export const EVENTS_FETCH_EVENTS = 'EVENTS_FETCH_EVENTS'
export const EVENTS_FETCH_EVENT = 'EVENTS_FETCH_EVENT'

// -- DOCTOR EVENTS
export const EVENTS_FETCH_DOCTOR_EVENTS = 'EVENTS_FETCH_DOCTOR_EVENTS'
export const EVENTS_FETCH_DOCTOR_EVENT = 'EVENTS_FETCH_DOCTOR_EVENT'

// -- TASK EVENTS
export const EVENTS_FETCH_TASK_EVENTS = 'EVENTS_FETCH_TASK_EVENTS'
export const EVENTS_FETCH_TASK_EVENT = 'EVENTS_FETCH_TASK_EVENT'


// MEDICATIONS
// -- MEDICAL EXAMINATION TYPE
export const MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES = 'MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPES'
export const MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE = 'MEDICATIONS_FETCH_MEDICAL_EXAMINATION_TYPE'

// -- DOCTOR
export const MEDICATIONS_FETCH_DOCTORS = 'MEDICATIONS_FETCH_DOCTORS'
export const MEDICATIONS_FETCH_DOCTOR = 'MEDICATIONS_FETCH_DOCTOR'

// -- THERAPY
export const MEDICATIONS_FETCH_THERAPIES = 'MEDICATIONS_FETCH_THERAPIES'
export const MEDICATIONS_FETCH_THERAPY = 'MEDICATIONS_FETCH_THERAPY'

// -- DIAGNOSIS
export const MEDICATIONS_FETCH_ALL_DIAGNOSIS = 'MEDICATIONS_FETCH_ALL_DIAGNOSIS'
export const MEDICATIONS_FETCH_DIAGNOSIS = 'MEDICATIONS_FETCH_DIAGNOSIS'
export const MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS = 'MEDICATIONS_UPDATE_DIAGNOSIS_DETAILS'
export const MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS = 'MEDICATIONS_CLEAR_DIAGNOSIS_DETAILS'

// -- MEDICATION
export const MEDICATIONS_FETCH_MEDICATIONS = 'MEDICATIONS_FETCH_MEDICATIONS'
export const MEDICATIONS_FETCH_MEDICATION = 'MEDICATIONS_FETCH_MEDICATION'

// -- MEDICAL EXAMINATION
export const MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS = 'MEDICATIONS_FETCH_MEDICAL_EXAMINATIONS'
export const MEDICATIONS_FETCH_MEDICAL_EXAMINATION = 'MEDICATIONS_FETCH_MEDICAL_EXAMINATION'

// -- MEDICATION SCHEDULE
export const MEDICATIONS_FETCH_MEDICATION_SCHEDULES = 'MEDICATIONS_FETCH_MEDICATION_SCHEDULES'
export const MEDICATIONS_FETCH_MEDICATION_SCHEDULE = 'MEDICATIONS_FETCH_MEDICATION_SCHEDULE'

// -- THERAPY SCHEDULE
export const MEDICATIONS_FETCH_THERAPY_SCHEDULES = 'MEDICATIONS_FETCH_THERAPY_SCHEDULES'
export const MEDICATIONS_FETCH_THERAPY_SCHEDULE = 'MEDICATIONS_FETCH_THERAPY_SCHEDULE'

export const MEDICATIONS_UPDATE_DOCTOR_DETAILS = 'MEDICATIONS_UPDATE_DOCTOR_DETAILS'
export const MEDICATIONS_CLEAR_DOCTOR_DETAILS = 'MEDICATIONS_CLEAR_DOCTOR_DETAILS'


// RESIDENTS
// -- RESIDENT
export const FETCH_RESIDENTS = 'FETCH_RESIDENTS'
export const FETCH_RESIDENT = 'FETCH_RESIDENT'
export const RESIDENTS_LOADING = 'RESIDENTS_LOADING'
export const RESIDENT_LOADING = 'RESIDENT_LOADING'
export const RESIDENT_REQUEST_UPDATING = 'RESIDENT_REQUEST_UPDATING'
export const CLEANUP_RESIDENTS = 'CLEANUP_RESIDENTS'

// -- MEDICAL REPORT
export const RESIDENTS_FETCH_MEDICAL_REPORTS = 'RESIDENTS_FETCH_MEDICAL_REPORTS'
export const RESIDENTS_FETCH_MEDICAL_REPORT = 'RESIDENTS_FETCH_MEDICAL_REPORT'
export const RESIDENTS_UPDATE_MEDICAL_REPORT = 'RESIDENTS_UPDATE_MEDICAL_REPORT'
export const RESIDENTS_CLEAR_MEDICAL_REPORT = 'RESIDENTS_CLEAR_MEDICAL_REPORT'

// -- FAMILY MEMBER
export const RESIDENTS_FETCH_FAMILY_MEMBERS = 'RESIDENTS_FETCH_FAMILY_MEMBERS'
export const RESIDENTS_FETCH_FAMILY_MEMBER = 'RESIDENTS_FETCH_FAMILY_MEMBER'

// -- EMERGENCY CONTACT
export const RESIDENTS_FETCH_EMERGENCY_CONTACTS = 'RESIDENTS_FETCH_EMERGENCY_CONTACTS'
export const RESIDENTS_FETCH_EMERGENCY_CONTACT = 'RESIDENTS_FETCH_EMERGENCY_CONTACT'

export const RESIDENTS_UPDATE_RESIDENT_DETAILS = 'RESIDENTS_UPDATE_RESIDENT_DETAILS'
export const RESIDENTS_CLEAR_RESIDENT_DETAILS = 'RESIDENTS_CLEAR_RESIDENT_DETAILS'
export const RESIDENT_UPDATE_RESIDENT_REQUEST = 'RESIDENT_UPDATE_RESIDENT_REQUEST'
export const RESIDENT_CREATE = 'RESIDENT_CREATE'
export const RESIDENT_UPDATE = 'RESIDENT_UPDATE'

// TASKS
// -- TASK ATTACHMENT
export const TASKS_FETCH_TASK_ATTACHMENTS = 'TASKS_FETCH_TASK_ATTACHMENTS'
export const TASKS_FETCH_TASK_ATTACHMENT = 'TASKS_FETCH_TASK_ATTACHMENT'

// -- TASK
export const TASKS_FETCH_TASKS = 'TASKS_FETCH_TASKS'
export const TASKS_FETCH_TASK = 'TASKS_FETCH_TASK'
export const TASKS_LOADING = 'TASKS_LOADING'
export const TASK_LOADING = 'TASK_LOADING'
export const TASK_CREATE = 'TASK_CREATE'
export const TASK_UPDATE = 'TASK_UPDATE'
export const CLEANUP_TASKS = 'CLEANUP_TASKS'

// -- COMMENT
export const TASKS_FETCH_COMMENTS = 'TASKS_FETCH_COMMENTS'
export const TASKS_FETCH_COMMENT = 'TASKS_FETCH_COMMENT'

// -- COMMENT_ATTACHMENT
export const TASKS_FETCH_COMMENT_ATTACHMENTS = 'TASKS_FETCH_COMMENT_ATTACHMENTS'
export const TASKS_FETCH_COMMENT_ATTACHMENT = 'TASKS_FETCH_COMMENT_ATTACHMENT'


// AUTH
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER'
export const AUTH_SAVE_USER = 'AUTH_SAVE_USER'
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
export const CLEANUP_AUTH = 'CLEANUP_AUTH'

// UI
export const UI_UPDATE_ACTIVE_TAB = 'UI_UPDATE_ACTIVE_TAB'
export const UI_UPDATE_TAB_LIST = 'UI_UPDATE_TAB_LIST'
export const UI_CLEANUP_TAB_LIST = 'UI_CLEANUP_TAB_LIST'
