import {authorized} from '../../axios'
import {
    CLEANUP_RESIDENTS,
    FETCH_RESIDENT,
    FETCH_RESIDENTS, RESIDENT_CREATE,
    RESIDENT_LOADING, RESIDENT_REQUEST_UPDATING, RESIDENT_UPDATE, RESIDENT_UPDATE_RESIDENT_REQUEST,
    RESIDENTS_CLEAR_MEDICAL_REPORT,
    RESIDENTS_CLEAR_RESIDENT_DETAILS,
    RESIDENTS_FETCH_EMERGENCY_CONTACT,
    RESIDENTS_FETCH_EMERGENCY_CONTACTS,
    RESIDENTS_FETCH_FAMILY_MEMBER,
    RESIDENTS_FETCH_FAMILY_MEMBERS,
    RESIDENTS_FETCH_MEDICAL_REPORT,
    RESIDENTS_FETCH_MEDICAL_REPORTS,
    RESIDENTS_LOADING,
    RESIDENTS_UPDATE_MEDICAL_REPORT,
    RESIDENTS_UPDATE_RESIDENT_DETAILS
} from './action.types'
import {resident_request_formatter} from "../../components/form_parsers/resident_request_formatter";
import {errorResponse, successResponse} from "../../components/shared/utils";

const primary_slug = 'residents/api/v1'

export const ResidentActions = {

    fetchResident: (token, id) => dispatch => {
        const url = `${primary_slug}/resident/${id}/`
        dispatch({type: RESIDENT_LOADING})
        authorized(token).get(url).then(resp => {
            return dispatch({
                type: FETCH_RESIDENT,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_RESIDENT,
                payload: errorResponse(reason)
            })
        })
    },

    fetchResidents: (token) => dispatch => {
        const url = `${primary_slug}/resident/`
        dispatch({type: RESIDENTS_LOADING})
        authorized(token).get(url).then(resp => {
            return dispatch({
                type: FETCH_RESIDENTS,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_RESIDENTS,
                payload: errorResponse(reason)
            })
        })
    },

    fetchMedicalReport: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_report/${id}/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_MEDICAL_REPORT,
                payload: resp.data
            }))
    },

    fetchMedicalReports: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/medical_report/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_MEDICAL_REPORTS,
                payload: resp.data
            }))
    },

    fetchFamilyMember: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/family_member/${id}/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_FAMILY_MEMBER,
                payload: resp.data
            }))
    },

    fetchFamilyMembers: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/family_member/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_FAMILY_MEMBERS,
                payload: resp.data
            }))
    },

    fetchEmergencyContact: (token, id) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/emergency_contact/${id}/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_EMERGENCY_CONTACT,
                payload: resp.data
            }))
    },

    fetchEmergencyContacts: (token) => dispatch => {
        authorized(token)
            .get(`${primary_slug}/emergency_contact/`)
            .then(resp => dispatch({
                type: RESIDENTS_FETCH_EMERGENCY_CONTACTS,
                payload: resp.data
            }))
    },

    updateResidentDetails: (name, value) => dispatch => {
        dispatch({
            type: RESIDENTS_UPDATE_RESIDENT_DETAILS,
            payload: {name, value}
        })
    },

    clearResidentDetails: () => dispatch => {
        dispatch({
            type: RESIDENTS_CLEAR_RESIDENT_DETAILS
        })
    },

    createResident: (token, data) => dispatch => {
        resident_request_formatter(data).then(formatted_data => {
            authorized(token)
                .post(`${primary_slug}/resident/`, formatted_data)
                .then(resp => {
                    return dispatch({
                        type: RESIDENT_CREATE,
                        payload: successResponse(resp)
                    })
                })
                .catch(error => {
                    return dispatch({
                        type: RESIDENT_CREATE,
                        payload: errorResponse(error)
                    })
                })
        })
    },

    updateReportDetails: (name, value) => dispatch => {
        dispatch({
            type: RESIDENTS_UPDATE_MEDICAL_REPORT,
            payload: {name, value}
        })
    },

    clearReportDetails: () => dispatch => {
        dispatch({
            type: RESIDENTS_CLEAR_MEDICAL_REPORT
        })
    },

    createReport: (token, data) => dispatch => {
        authorized(token)
            .post(`${primary_slug}/medical_report/`, data)
            .then(() => alert('Success'))
            .catch(alert)
    },

    saveResident: (token, id, data) => dispatch => {
        resident_request_formatter(data).then(formatted_data => {
            authorized(token)
                .put(`${primary_slug}/resident/${id}/`, formatted_data)
                .then(resp => {
                    return dispatch({
                        type: RESIDENT_UPDATE,
                        payload: successResponse(resp)
                    })
                })
                .catch(error => {
                    return dispatch({
                        type: RESIDENT_UPDATE,
                        payload: errorResponse(error)
                    })
                })
        })
    },

    saveReport: (token, id, data) => dispatch => {
        authorized(token)
            .put(`${primary_slug}/medical_report/${id}/`, data)
            .then(() => alert('Success'))
            .catch(alert)
    },

    cleanup: () => dispatch => {
        dispatch({
            type: CLEANUP_RESIDENTS
        })
    },

    updateResidentRequest: (newResidentValues, hasMore) => dispatch => {
        dispatch({ type: RESIDENT_REQUEST_UPDATING })
        dispatch({
            type: RESIDENT_UPDATE_RESIDENT_REQUEST,
            payload: { newResidentValues, hasMore }
        })
    }
}
