import React from "react";
import {INPUT_BOOLEAN, INPUT_DATE, INPUT_NUMBER, INPUT_TEXT, SELECTION_SINGLE} from "./FieldTypes";
import {GenderOptions, ResidentCurrentLiving, WalkingAbility} from "./SelectOptions";
import HomeIcon from "@material-ui/icons/Home";
import WcIcon from "@material-ui/icons/Wc";
import PersonIcon from "@material-ui/icons/Person";
import {Healing} from "@material-ui/icons";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import SportsHockeyIcon from "@material-ui/icons/SportsHockey";
import GestureIcon from "@material-ui/icons/Gesture";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import SportsHandballIcon from "@material-ui/icons/SportsHandball";
import PetsIcon from "@material-ui/icons/Pets";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import TodayIcon from "@material-ui/icons/Today";
import PlaceIcon from "@material-ui/icons/Place";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BusinessIcon from "@material-ui/icons/Business";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EventNoteIcon from "@material-ui/icons/EventNote";

export const ResidentFields = [
    {
        name: "full_name",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Full name",
        helperText: "The resident's full name",
        required: false,
        displayed: true,
        icon: <PersonIcon/>,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "social_no",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Social number",
        helperText: "The resident's social number",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "guardian",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Guardian",
        helperText: "The resident's guardian",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "address",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Address",
        helperText: "The resident's address",
        required: false,
        displayed: true,
        icon: <HomeIcon/>,
        multiline: false,
        gridSpace: 8
    },
    {
        name: "years_at_address",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "Years at address",
        helperText: "How long has the resident lived on the address",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "birth_date",
        type: INPUT_DATE,
        isSelection: false,
        label: "Birth date",
        helperText: "The resident's date of birth",
        required: false,
        displayed: true,
        icon: <TodayIcon/>,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "birth_place",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Birth place",
        helperText: "The resident's city of birth",
        required: false,
        displayed: true,
        icon: <PlaceIcon/>,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "gender",
        type: SELECTION_SINGLE,
        isSelection: true,
        options: GenderOptions,
        label: "Resident's gender",
        helperText: "Select the resident's gender",
        required: false,
        displayed: true,
        icon: <WcIcon/>,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "telephone_number",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Telephone number",
        helperText: "The resident's telephone number",
        required: false,
        displayed: true,
        icon: <ContactPhoneIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "current_living",
        type: SELECTION_SINGLE,
        isSelection: true,
        options: ResidentCurrentLiving,
        label: "Living environment",
        helperText: "The resident's living environment",
        required: false,
        displayed: true,
        icon: <ApartmentIcon/>,
        multiline: false,
        gridSpace: 3
    },
    {
        name: "own_car",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Owns car",
        helperText: "Does the resident own a car",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 3
    },
    {
        name: "drive_regularly",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Drives regularly",
        helperText: "Does the resident drive regularly",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 3
    },
    {
        name: "maintain_car",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Maintain car",
        helperText: "Does the resident maintain a vehicle",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 3
    },
    {
        name: "living_concerns",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Living concerns",
        helperText: "Detailed explanation of any present/past living concerns",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "require_living_assistant",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Requires assistant",
        helperText: "Does the resident require an assistant",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "assistant",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Living assistant",
        helperText: "The full name of the patient's living assistant",
        required: false,
        displayed: true,
        icon: <Healing/>,
        multiline: false,
        gridSpace: 8
    },
    {
        name: "reason_assistant",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Living assistant reason",
        helperText: "The purpose for using a living assistant",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "day_visit",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Day visit",
        helperText: "Does the resident requires daily visits",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "length_day_visit",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "Day visit length",
        helperText: "The estimated length of the resident's day visit",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 8
    },
    {
        name: "reason_day_visit",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Day visit reason",
        helperText: "The purpose for a daily visit",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "physician",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Physician",
        helperText: "The full name of the patient's physician",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "physician_address",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Physician's address",
        helperText: "The address of the patient's physician",
        required: false,
        displayed: true,
        icon: <BusinessIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "physician_telephone",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Physician's mobile phone",
        helperText: "The mobile phone of the patient's physician",
        required: false,
        displayed: true,
        icon: <ContactPhoneIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "hospital",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Physician's hospital",
        helperText: "The main hospital of the patient's physician",
        required: false,
        displayed: true,
        icon: <LocalHospitalIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "describe_state_of_health",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Health State",
        helperText: "Detailed description of the resident's health",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "often_doctor_visit",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Doctor visit rate",
        helperText: "The regularity of needed doctor visits ex. daily",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "last_doctor_visit",
        type: INPUT_DATE,
        isSelection: false,
        label: "Last doctor visit",
        helperText: "The date of the most recent doctor visit",
        required: false,
        displayed: true,
        icon: <EventNoteIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "walking",
        type: SELECTION_SINGLE,
        isSelection: true,
        options: WalkingAbility,
        label: "Walking ability",
        helperText: "The resident's ability to walk",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "walking_instrument",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Walking instrument",
        helperText: "The patient's necessary aid for walking",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "if_medications",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Requires medications",
        helperText: "Does the resident use medications",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "medications_assistance",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Requires medications assistance",
        helperText: "Does the resident require assistance in taking medications",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "medications",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Medication description",
        helperText: "Detailed explanation of any present/past required medications",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },

    {
        name: "own_meals",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Own meals",
        helperText: "Does the resident prepare meals",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "if_diet",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Special diet",
        helperText: "Does the resident require a special diet",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "diet",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Diet description",
        helperText: "Detailed description of the resident's required diet",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "health_insurance",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Health insurance",
        helperText: "Does the resident drive regularly have health insurance",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "medicare_insurance",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Medicare insurance",
        helperText: "Does the resident drive regularly have medicare insurance",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 6
    },
    {
        name: "insurances",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Insurance description",
        helperText: "Detailed description of the resident's insurances",
        required: false,
        displayed: true,
        icon: null,
        multiline: true,
        gridSpace: 12
    },
    {
        name: "ad8_score",
        type: INPUT_NUMBER,
        isSelection: false,
        label: "AD8 Score",
        helperText: "The resident's AD8 test score",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "handle_bathing",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handles bathing",
        helperText: "Does the resident handle bathing",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_dressing",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle dressing",
        helperText: "Does the resident handle dressing",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_mouth_skin_care",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle mouth skin care",
        helperText: "Does the resident handle mouth skin care",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_grooming",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle grooming",
        helperText: "Does the resident handle grooming",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_toileting",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "handle toileting",
        helperText: "Does the resident handle toileting",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_mobility",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "handle mobility",
        helperText: "Does the resident handle mobility",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_med_reminder",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Medication reminder",
        helperText: "Does the resident need a medication reminder",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_night_care",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle night care",
        helperText: "Does the resident handle night care",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_housekeeping",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle housekeeping",
        helperText: "Does the resident handle housekeeping",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "handle_clothing",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Handle clothing",
        helperText: "Does the resident handle clothing",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 4
    },
    {
        name: "place_born",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Place born",
        helperText: "The city where the resident is born",
        required: false,
        displayed: true,
        icon: <PlaceIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "place_childhood",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Place childhood",
        helperText: "The city of the resident's childhood",
        required: false,
        displayed: true,
        icon: <PlaceIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "place_young_adult",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Place young adult",
        helperText: "The city of the resident's young adulthood",
        required: false,
        displayed: true,
        icon: <PlaceIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "place_older_adult",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Place older adult",
        helperText: "The city of the resident's late adulthood",
        required: false,
        displayed: true,
        icon: <PlaceIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "education",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Education",
        helperText: "The resident's education information",
        required: false,
        displayed: true,
        icon: <CastForEducationIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "employment",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Employment",
        helperText: "The resident's employment information",
        required: false,
        displayed: true,
        icon: <CastForEducationIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "other_interests",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Other interests",
        helperText: "The resident's other interests",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "vacation_travel_spots",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Vacation travel spots",
        helperText: "The resident's favorite travel spots",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "religion",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Religion",
        helperText: "The resident's practicing religion",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "military",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Military",
        helperText: "The resident's military background",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "personal_characteristics",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Personal characteristics",
        helperText: "The resident's general personal characteristics",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "other_information",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Other information",
        helperText: "Additional relevant information about the resident",
        required: false,
        displayed: true,
        icon: null,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "listening_music",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Music preference",
        helperText: "The type of music the resident is fond of",
        required: false,
        displayed: true,
        icon: <MusicNoteIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "singing",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Singing preference",
        helperText: "The resident's singing ability",
        required: false,
        displayed: true,
        icon: <LibraryMusicIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "playing_instruments",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Playing instrument",
        helperText: "The resident's instrument of choice",
        required: false,
        displayed: true,
        icon: <AccessibilityIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "dancing",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Dancing",
        helperText: "The resident's dancing ability",
        required: false,
        displayed: true,
        icon: <EmojiPeopleIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "enjoy_pets",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Pets",
        helperText: "The resident's affection towards animals",
        required: false,
        displayed: true,
        icon: <PetsIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "sports",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Sports",
        helperText: "The resident's affection towards sports",
        required: false,
        displayed: true,
        icon: <SportsHandballIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "exercising",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Exercising",
        helperText: "The resident's affection towards exercising",
        required: false,
        displayed: true,
        icon: <SportsKabaddiIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "reading",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Reading",
        helperText: "The resident's affection towards reading",
        required: false,
        displayed: true,
        icon: <LocalLibraryIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "card_games",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Card games",
        helperText: "The resident's affection towards card games",
        required: false,
        displayed: true,
        icon: <DashboardIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "board_games",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Board games",
        helperText: "The resident's affection towards board games",
        required: false,
        displayed: true,
        icon: <VideogameAssetIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "drawing",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Drawing",
        helperText: "The resident's affection towards drawing",
        required: false,
        displayed: true,
        icon: <GestureIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "knitting",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Knitting",
        helperText: "The resident's affection towards knitting",
        required: false,
        displayed: true,
        icon: <SportsHockeyIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "sightseeing",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Sightseeing",
        helperText: "The resident's affection towards sightseeing",
        required: false,
        displayed: true,
        icon: <FilterHdrIcon/>,
        multiline: false,
        gridSpace: 12
    },
    {
        name: "other_activities",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Other activities",
        helperText: "Other relevant interests",
        required: false,
        displayed: true,
        icon: <LocalActivityIcon/>,
        multiline: false,
        gridSpace: 12
    },
]