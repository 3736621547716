import React from "react";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CallIcon from "@material-ui/icons/Call";
import {INPUT_BOOLEAN, INPUT_TEXT, INPUT_TIME} from "./FieldTypes";

export const GuardianFormFields = [
    {
        name: "mobile_phone",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Phone number",
        helperText: "The guardian's mobile phone number",
        required: false,
        displayed: true,
        icon: <PhoneAndroidIcon/>,
        gridSpace: 6
    },
    {
        name: "mobile_phone2",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Phone number 2",
        helperText: "The guardian's second mobile phone number",
        required: false,
        displayed: true,
        icon: <PhoneAndroidIcon/>,
        gridSpace: 6
    },
    {
        name: "home_phone",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Home phone number",
        helperText: "The guardian's home phone number",
        required: false,
        displayed: true,
        icon: <CallIcon/>,
        gridSpace: 6
    },
    {
        name: "work_phone",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Work phone number",
        helperText: "The guardian's work phone number",
        required: false,
        displayed: true,
        icon: <CallIcon/>,
        gridSpace: 6
    },
    {
        name: "work_hours_from",
        type: INPUT_TIME,
        isSelection: false,
        label: "Time of start of work",
        helperText: "The time of day the guardian starts working",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 4

    },
    {
        name: "work_hours_to",
        type: INPUT_TIME,
        isSelection: false,
        label: "Time of end of work",
        helperText: "The time of day the guardian ends working",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 4
    },
    {
        name: "work_weekends",
        type: INPUT_BOOLEAN,
        isSelection: false,
        label: "Works weekends",
        helperText: "Indicates whether the guardian works on weekends",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 4
    }
]
