import {authorized} from '../../axios'
import {AUTH_REGISTER_USER, AUTH_SAVE_USER, CLEANUP_AUTH, FETCH_ACCOUNTS} from "./action.types";
import {errorResponse, successResponse} from "../../components/shared/utils";

export const AuthActions = {
    registerUser: (token, data) => dispatch => {
        const url = 'accounts/api/v1/register/'
        authorized(token).post(url, data).then(resp => {
            return dispatch({
                type: AUTH_REGISTER_USER,
                payload: successResponse(resp)
            })
        }).catch(error => {
            return dispatch({
                type: AUTH_REGISTER_USER,
                payload: errorResponse(error)
            })
        })
    },

    saveAccount: (token, id, type, data) => dispatch => {
        const url = `accounts/api/v1/${type}/${id}/`
        authorized(token).put(url, data)
            .then(resp => {
                return dispatch({
                    type: AUTH_SAVE_USER,
                    payload: successResponse(resp)
                })
            })
            .catch(error => {
                return dispatch({
                    type: AUTH_SAVE_USER,
                    payload: errorResponse(error)
                })
            })
    },

    fetchAccounts: (token) => dispatch => {
        const url = `accounts/api/v1/user/`
        authorized(token).get(url)
            .then(resp => {
                return dispatch({
                    type: FETCH_ACCOUNTS,
                    payload: successResponse(resp)
                })
            }).catch(error => {
                return dispatch({
                    type: FETCH_ACCOUNTS,
                    payload: errorResponse(error)
                })
        })
    },

    cleanup: () => dispatch => {
        dispatch({
            type: CLEANUP_AUTH
        })
    }
}


