import {makeStyles} from "@material-ui/core/styles";

export const useTableRowStyles = makeStyles((theme) => ({
    root: {},
    content: {
        color: theme.palette.primary.dark,
    },
    dual: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(2)
        }
    }
}))