import React from "react";
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {TimeIcon} from "@material-ui/pickers/_shared/icons/TimeIcon";
import {SelectComponent} from "./SelectComponent";
import {FormControl, FormControlLabel, FormGroup, FormHelperText, Switch, TextField} from "@material-ui/core";
import {useInputStyles} from "./InputStyles";
import {INPUT_BOOLEAN, INPUT_DATE, INPUT_DATETIME, INPUT_TIME} from "../fields/FieldTypes";

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

export const InputComponent = ({
                                   isSelection = false,
                                   options = [],
                                   type,
                                   name,
                                   value,
                                   label,
                                   helperText,
                                   placeholder,
                                   defaultValue,
                                   icon,
                                   changeHandler,
                                   multiline = false
                               }) => {
    const classes = useInputStyles()

    const handleDateChange = (date) => {
        const formatted = date.format(DATE_FORMAT)
        changeHandler({
            target: {
                name: name,
                value: formatted
            }
        })
    }

    const handleTimeChange = (date) => {
        const formatted = date.format(TIME_FORMAT)
        changeHandler({
            target: {
                name: name,
                value: formatted
            }
        })
    }

    const handleDateTimeChange = (date) => {
        const formatted = date.format(DATETIME_FORMAT)
        changeHandler({
            target: {
                name: name,
                value: formatted
            }
        })
    }

    const handleSwitchChange = (event) => {
        const switchName = event.target.name
        const checked = event.target.checked
        changeHandler({
            target: {
                name: switchName,
                value: checked
            }
        })
    }

    if (isSelection) {
        return (
            <SelectComponent name={name}
                             value={value}
                             helperText={helperText}
                             changeHandler={changeHandler}
                             label={label}
                             type={type}
                             icon={icon}/>
        )
    }

    if (type === INPUT_DATE) {
        if (!value) value = undefined
        return (
            <div className={classes.root}>
                <KeyboardDatePicker
                    name={name}
                    value={value}
                    format={DATE_FORMAT}
                    label={label}
                    onChange={handleDateChange}
                    helperText={helperText}
                    fullWidth
                />
            </div>
        )
    } else if (type === INPUT_TIME) {
        if (value) {
            const dateParts = value.split(":")
            value = new Date().setHours(dateParts[0], dateParts[1])
        } else {
            value = undefined
        }
        return (
            <div className={classes.root}>
                <KeyboardTimePicker
                    name={name}
                    value={value ? value : undefined}
                    format={TIME_FORMAT}
                    label={label}
                    onChange={handleTimeChange}
                    helperText={helperText}
                    fullWidth
                    keyboardIcon={<TimeIcon/>}
                />
            </div>
        )
    } else if (type === INPUT_BOOLEAN) {
        if (value === "") {
            value = false
        }
        return (
            <div className={classes.root}>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            style={{
                                textTransform: "capitalize"
                            }}
                            control={
                                <Switch checked={value}
                                        onChange={handleSwitchChange}
                                        name={name}/>
                            }
                            label={label}
                            labelPlacement="top"/>
                    </FormGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            </div>
        )
    } else if (type === INPUT_DATETIME) {
        if (!value) value = undefined
        return (
            <div className={classes.root}>
                <KeyboardDateTimePicker
                    name={name}
                    value={value}
                    format={DATETIME_FORMAT}
                    label={label}
                    onChange={handleDateTimeChange}
                    helperText={helperText}
                    fullWidth
                />
            </div>
        )
    } else return (
        <div className={classes.root}>
            <div className={classes.icon}>
                {icon}
            </div>
            <TextField
                variant={multiline ? 'outlined' : 'standard'}
                color={'primary'}
                type={type}
                name={name}
                value={value}
                defaultValue={defaultValue}
                onChange={changeHandler}
                multiline={multiline}
                rows={multiline ? 12 : 1}
                label={label}
                helperText={helperText}
                placeholder={placeholder}
                InputProps={{
                    classes: {
                        input: classes.input,
                        root: classes.inputRoot,
                    },
                }}
                fullWidth
            />
        </div>
    )
}