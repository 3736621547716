import React from "react";
import {useFormComponentService} from "./FormComponentService";
import {Grid} from "@material-ui/core";
import {InputComponent} from "./InputComponent";
import {Spinner} from "../Spinner";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    buttons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: theme.spacing(3)
    }
}))

export const FormComponent = ({
                                  fields,
                                  formButtonText,
                                  secondFormButtonText,
                                  onSubmit,
                                  secondButtonOnSubmit,
                                  initialValues
                              }) => {

    const {
        formState,
        changeHandler,
        onSubmitWrapper,
        onSecondButtonSubmitWrapper
    } = useFormComponentService({
        formFields: fields,
        onSubmit,
        secondButtonOnSubmit,
        initialValues
    })
    const classes = useStyles()

    if (!formState)
        return <Spinner/>

    return (
        <Grid container spacing={8} component={"form"} style={{margin: 0, width: '100%'}}>
            {
                fields.map(field => (
                    <Grid item xs={field.gridSpace} key={field.name}>
                        <InputComponent key={field.name}
                                        changeHandler={changeHandler}
                                        isSelection={field.isSelection}
                                        options={field.isSelection ? field.options : []}
                                        value={formState[field.name]}
                                        type={field.type}
                                        name={field.name}
                                        label={field.label}
                                        multiline={field.multiline}
                                        helperText={field.helperText}
                                        icon={field.icon}/>
                    </Grid>
                ))
            }
            <Grid item xs={12} className={classes.buttons}>
                {secondFormButtonText &&
                        <Button color={'secondary'}
                                size={'large'}
                                variant={'contained'}
                                onClick={onSecondButtonSubmitWrapper}>
                            {secondFormButtonText}
                        </Button>
                }
                {formButtonText &&
                        <Button color={'secondary'}
                                size={'large'}
                                variant={'contained'}
                                onClick={onSubmitWrapper}>
                            {formButtonText}
                        </Button>
                }
            </Grid>
        </Grid>
    )
}