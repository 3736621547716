import {
    CLEANUP_RESIDENTS,
    FETCH_RESIDENT,
    FETCH_RESIDENTS,
    RESIDENT_CREATE,
    RESIDENT_LOADING,
    RESIDENT_REQUEST_UPDATING,
    RESIDENT_UPDATE,
    RESIDENT_UPDATE_RESIDENT_REQUEST,
    RESIDENTS_FETCH_EMERGENCY_CONTACT,
    RESIDENTS_FETCH_EMERGENCY_CONTACTS,
    RESIDENTS_FETCH_FAMILY_MEMBER,
    RESIDENTS_FETCH_FAMILY_MEMBERS,
    RESIDENTS_FETCH_MEDICAL_REPORT,
    RESIDENTS_FETCH_MEDICAL_REPORTS,
    RESIDENTS_LOADING,
    RESIDENTS_UPDATE_MEDICAL_REPORT,
    RESIDENTS_UPDATE_RESIDENT_DETAILS
} from '../actions/action.types'

const initialState = {
    areResidentsLoading: false,
    isResidentLoading: false,
    isRequestUpdating: true,
    residents: null,
    residentsFetchStatus: null,
    residentsFetchMessage: null,
    resident: null,
    residentFetchStatus: null,
    residentFetchMessage: null,
    residentRequest: null,
    residentCreateStatus: null,
    residentCreateMessage: null,
    residentUpdateStatus: null,
    residentUpdateMessage: null,
    emergency_contact_details: null,
    emergency_contacts: null,
    family_member_details: null,
    family_members: null,
    medical_report_details: null,
    medical_reports: null,
    resident_details: null
}

export const ResidentReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESIDENTS_LOADING: {
            return {
                ...state,
                areResidentsLoading: true
            }
        }
        case RESIDENT_LOADING: {
            return {
                ...state,
                isResidentLoading: true
            }
        }
        case RESIDENT_REQUEST_UPDATING: {
            return {
                ...state,
                isRequestUpdating: true
            }
        }
        case FETCH_RESIDENTS: {
            return {
                ...state,
                areResidentsLoading: false,
                residents: action.payload.data,
                residentsFetchStatus: action.payload.status,
                residentsFetchMessage: action.payload.message,
            }
        }
        case FETCH_RESIDENT: {
            return {
                ...state,
                isResidentLoading: false,
                resident: action.payload.data,
                residentFetchStatus: action.payload.status,
                residentFetchMessage: action.payload.message,
            }
        }
        case CLEANUP_RESIDENTS: {
            return initialState
        }
        case RESIDENTS_FETCH_EMERGENCY_CONTACT:
            return {
                ...state,
                emergency_contact_details: action.payload
            }
        case RESIDENTS_FETCH_EMERGENCY_CONTACTS:
            return {
                ...state,
                emergency_contacts: action.payload
            }
        case RESIDENTS_FETCH_FAMILY_MEMBER:
            return {
                ...state,
                family_member_details: action.payload
            }
        case RESIDENTS_FETCH_FAMILY_MEMBERS:
            return {
                ...state,
                family_members: action.payload
            }
        case RESIDENTS_FETCH_MEDICAL_REPORT:
            return {
                ...state,
                medical_report_details: action.payload
            }
        case RESIDENTS_FETCH_MEDICAL_REPORTS:
            return {
                ...state,
                medical_reports: action.payload
            }
        case RESIDENTS_UPDATE_RESIDENT_DETAILS:
            return {
                ...state,
                resident_details: {
                    ...state.resident_details,
                    [action.payload.name]: action.payload.value
                }
            }
        case RESIDENTS_UPDATE_MEDICAL_REPORT: {
            return {
                ...state,
                medical_report_details: {
                    ...state.medical_report_details,
                    [action.payload.name]: action.payload.value
                }
            }
        }
        case RESIDENT_UPDATE_RESIDENT_REQUEST: {
            return {
                ...state,
                residentRequest: {
                    ...state.residentRequest,
                    ...action.payload.newResidentValues
                },
                isRequestUpdating: action.payload.hasMore
            }
        }
        case RESIDENT_CREATE: {
            return {
                ...state,
                residentCreateStatus: action.payload.status,
                residentCreateMessage: action.payload.message
            }
        }
        case RESIDENT_UPDATE: {
            return {
                ...state,
                residentUpdateStatus: action.payload.status,
                residentUpdateMessage: action.payload.message
            }
        }
        default:
            return {
                ...state
            }
    }
}
