import React from "react";
import {Route} from "react-router-dom";

const GuardedRoute = ({component: Component, isAuthenticated, ...rest}) => {
    return (
        <Route {...rest} render={
            props => {
                if (isAuthenticated) {
                    return <Component {...rest} {...props} />
                } else {
                    // TODO: fix
                    return null
                }
            }
        }/>
    )
}

export default GuardedRoute
