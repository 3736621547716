export const AuthUserPositionOptions = [
    {
        name: "field_worker_lead",
        checked: false,
        label: "Field worker lead"
    },
    {
        name: "field_worker",
        checked: false,
        label: "Field worker"
    },
    {
        name: "caretaker",
        checked: false,
        label: "Caretaker"
    },
    {
        name: "caretaker_lead",
        checked: false,
        label: "Caretaker lead"
    },
    {
        name: "operator",
        checked: false,
        label: "Operator"
    },
    {
        name: "operator_lead",
        checked: false,
        label: "Operator lead"
    },
    {
        name: "admin",
        checked: false,
        label: "Administrator"
    },
]

export const EmployeePaymentTypeOptions = [
    {
        name: "part_time",
        checked: true,
        label: "Part-Time"
    },
    {
        name: "salaried",
        checked: false,
        label: "Salaried"
    },
    {
        name: "contract",
        checked: false,
        label: "Contract"
    },
]

export const GenderOptions = [
    {
        name: "male",
        checked: false,
        label: "Male"
    },
    {
        name: "female",
        checked: false,
        label: "Female"
    },
    {
        name: "other",
        checked: true,
        label: "Other"
    },
]

export const ResidentCurrentLiving = [
    {
        name: "own_home",
        checked: true,
        label: "Own home"
    },
    {
        name: "renting",
        checked: false,
        label: "Renting"
    },
    {
        name: "single_family",
        checked: false,
        label: "Single family"
    },
    {
        name: "multi_family",
        checked: false,
        label: "Multi-family"
    },
]


export const WalkingAbility = [
    {
        name: "low",
        checked: true,
        label: "Low"
    },
    {
        name: "moderate",
        checked: false,
        label: "Moderate"
    },
    {
        name: "high",
        checked: false,
        label: "High"
    }
]

export const TaskPriorityOptions = [
    {
        name: "Urgent",
        checked: true,
        label: "Urgent"
    },
    {
        name: "High",
        checked: true,
        label: "High"
    },
    {
        name: "Medium",
        checked: true,
        label: "Medium"
    },
    {
        name: "Low",
        checked: true,
        label: "Low"
    }
]

export const TaskResolutionTypeOptions = [

    {
        name: "Answered",
        checked: true,
        label: "Answered"
    },
    {
        name: "Fixed",
        checked: true,
        label: "Fixed"
    },
    {
        name: "Blocked",
        checked: true,
        label: "Blocked"
    },
    {
        name: "Did not respond",
        checked: true,
        label: "Did not respond"
    },
    {
        name: "Duplicate",
        checked: true,
        label: "Duplicate"
    }
]