import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useDenseTableComponentService} from "./DenseTableComponentService";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        textAlign: "center"
    },
    headerCell: {
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
        textAlign: "center"
    }
}))

export const DenseTableComponent = ({
                                             headers,
                                             rows,
                                             navigateToDetails,
                                             fields
                                         }) => {
    const {getPropsFromNestedObject} = useDenseTableComponentService()
    const classes = useStyles()

    return (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell className={classes.headerCell}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((entity) => (
                            <TableRow key={entity.id}>
                                {fields?.map((field) => (
                                    <TableCell key={field.id} className={classes.tableCell}>{getPropsFromNestedObject(entity, field)}</TableCell>
                                ))}
                                <TableCell className={classes.tableCell}><Button display={"inline"}
                                                                                 variant={"contained"} color={"primary"}
                                                                                 size={"small"}
                                                                                 onClick={() => navigateToDetails(entity.id)}>Details</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}