import {CLEANUP_GUARDIANS, FETCH_GUARDIAN, FETCH_GUARDIANS, GUARDIAN_LOADING, GUARDIANS_LOADING} from "./action.types";
import {authorized} from "../../axios";
import {errorResponse, successResponse} from "../../components/shared/utils";

const primary_slug = 'accounts/api/v1'

export const GuardianActions = {

    fetchGuardian: (token, id) => dispatch => {
        const url = `${primary_slug}/guardian/${id}/`
        dispatch({type: GUARDIAN_LOADING})
        authorized(token).get(url).then(resp => {
            resp.data = {
                ...resp.data,
                ...resp.data.auth_user
            }
            return dispatch({
                type: FETCH_GUARDIAN,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_GUARDIAN,
                payload: errorResponse(reason)
            })
        })
    },

    fetchGuardians: token => dispatch => {
        const url = `${primary_slug}/guardian/`
        dispatch({type: GUARDIANS_LOADING})
        authorized(token).get(url).then(resp => {
            return dispatch({
                type: FETCH_GUARDIANS,
                payload: successResponse(resp)
            })
        }).catch(reason => {
            return dispatch({
                type: FETCH_GUARDIANS,
                payload: errorResponse(reason)
            })
        })
    },

    cleanup: () => dispatch => {
        dispatch({
            type: CLEANUP_GUARDIANS
        })
    }
}
