import {useEffect, useState} from "react";
import {SELECTION_SINGLE} from "../fields/FieldTypes";
import {useFormUtils} from "./FormUtils";
import {clone} from "../utils";

export const useFormComponentService = ({formFields, onSubmit, secondButtonOnSubmit, initialValues}) => {
    const [formState, setFormState] = useState(null)
    const formUtils = useFormUtils()

    const initForm = () => {
        const formObject = {}
        console.log(initialValues)
        for (const field of formFields) {
            if (field.isSelection) {
                // formObject[field.name] = JSON.parse(JSON.stringify(field.options))
                formObject[field.name] = clone(field.options)
                if (initialValues && initialValues[field.name]) {
                    for (let option of formObject[field.name]) {
                        if (field.type === SELECTION_SINGLE) {
                            option.checked = option.name === initialValues[field.name];
                        } else {
                            option.checked = !!initialValues[field.name].includes(option.name);
                        }
                    }
                    console.log(formObject[field.name])
                }
            } else {
                formObject[field.name] = formUtils.getValueToSet(field, initialValues)
            }
        }
        setFormState(formObject)
    }

    useEffect(initForm, [initialValues, formFields])

    const changeHandler = (event) => {
        const {name, value} = event.target
        setFormState(oldFormState => {
            return {
                ...oldFormState,
                [name]: value
            }
        })
    }

    const onSubmitWrapper = () => onSubmit(formState)
    const onSecondButtonSubmitWrapper = () => secondButtonOnSubmit(formState)


    return {
        formState,
        changeHandler,
        onSubmitWrapper,
        onSecondButtonSubmitWrapper
    }

}