import React from "react";
import {INPUT_DATETIME, INPUT_TEXT, SELECTION_SINGLE} from "./FieldTypes";
import {TaskPriorityOptions, TaskResolutionTypeOptions} from "./SelectOptions";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {CheckCircle} from "@material-ui/icons";

export const TaskFormFields = [
    {
        name: "title",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Title",
        helperText: "The task's title",
        required: false,
        displayed: true,
        icon: <AssignmentIcon/>,
        gridSpace: 6
    },
    {
        name: "status",
        type: INPUT_TEXT,
        isSelection: false,
        label: "Status",
        helperText: "The task's status",
        required: false,
        displayed: true,
        icon: <CheckCircle/>,
        gridSpace: 6
    },
    {
        name: "priority",
        type: SELECTION_SINGLE,
        isSelection: true,
        options: TaskPriorityOptions,
        label: "Priority",
        helperText: "The task's priority",
        required: false,
        displayed: true,
        gridSpace: 6
    },
    {
        name: "due_date",
        type: INPUT_DATETIME,
        isSelection: false,
        label: "Due date",
        helperText: "The task's due date",
        required: false,
        displayed: true,
        gridSpace: 6
    },
    {
        name: "resolution_type",
        type: SELECTION_SINGLE,
        isSelection: true,
        options: TaskResolutionTypeOptions,
        label: "Resolution type",
        helperText: "The task's resolution type",
        required: false,
        displayed: true,
        gridSpace: 12
    },
    {
        name: "resolution",
        type: INPUT_TEXT,
        multiline: true,
        isSelection: false,
        label: "Resolution",
        helperText: "The task's resolution",
        required: false,
        displayed: true,
        gridSpace: 12
    },
    {
        name: "assigned_to",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Assigned to",
        helperText: "The task's assignee",
        required: false,
        displayed: true,
        gridSpace: 6
    },
    {
        name: "created_by",
        type: SELECTION_SINGLE,
        isSelection: true,
        label: "Assigned by",
        helperText: "The task's assigner",
        required: false,
        displayed: true,
        gridSpace: 6
    },
    {
        name: "summary",
        type: INPUT_TEXT,
        multiline: true,
        isSelection: false,
        label: "Summary",
        helperText: "Task's summary",
        required: false,
        displayed: true,
        icon: null,
        gridSpace: 12
    }
]